import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { Row, Col, Container, Image, Form, Button, Spinner, Alert } from 'react-bootstrap';
import Calendar from 'react-calendar';
import Logo2 from '../../assets/img/bannerlagloria.png';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getToday } from '../../services/TimeService';
import private_key from '../../configs/keys'
import exitopago from '../../assets/img/exitopago.png';
import '../../assets/plansPricings.css';
import pasos3 from '../../assets/img/pasos_tres.png';
import pasos4 from '../../assets/img/pasos_cuatro.png';
import pasos5 from '../../assets/img/pasos_cinco.png';
import pasos6 from '../../assets/img/pasos_seis.png';
import uno from '../../assets/img/01_formulario.png';
import dos from '../../assets/img/02_formulario.png';
import tres from '../../assets/img/03_formulario.png';
import cuatro from '../../assets/img/04_formulario.png';
import cinco from '../../assets/img/05_formulario.png';
import iconoNombre from '../../assets/img/icononombre.png';
import iconoCorreo from '../../assets/img/iconocorreo.png';
import iconoTelefono from '../../assets/img/iconotelefono.png';
import iconoTarjeta from '../../assets/img/iconotarjeta.png';
import pagoAnticipo from '../../assets/img/pagoanticipo.png';
import icon_mañana from '../../assets/img/mañana.png';
import icon_tarde from '../../assets/img/tarde.png';
import icon_noche from '../../assets/img/noche.png';
import chimenea from '../../assets/img/chimenea.png';
import reservado from '../../assets/img/reservado.png';
import salon from '../../assets/img/salon.png';
import barra from '../../assets/img/barra.png';
import jardin from '../../assets/img/jardin.png';
import salagrande from '../../assets/img/salagrande.png';
import icon_mañana_sel from '../../assets/img/mañana_sel.png';
import icon_tarde_sel from '../../assets/img/tarde_sel.png';
import icon_noche_sel from '../../assets/img/noche_sel.png';
import { useHistory } from 'react-router-dom';

type MainPageCocktail = {
  userLabel?: string,
  passLabel?: string,
  rememberCheckboxLabel?: string,
  submitButtonLabel?: string,
  missingUserMsg?: string,
  missingPassMsg?: string,
  loginFailedMsg?: string
}

type Main2Props = {
  nameClient: string,
  mailClient:string,
  phoneClient:string,
  sesion:string
}
const MainPageCocktail:FunctionComponent<Main2Props> = props => {

  const { 
    nameClient,
    mailClient,
    phoneClient,
    sesion
  } = props;
  
  const history = useHistory();

  const crypto = require('crypto');

  const [Pagando, setPagando] = useState(false);
  const [Agenda, setAgenda] = useState([]);
  const [errorMessage, SeterrorMessage] = useState("Transaccion Fallida, intentelo de nuevo mas tarde.");
  const [showError, setShowError] = useState(false);
  const [valueCalendar, onChangeCalendar] = useState(new Date());
  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [cvv, setCVV] = useState('');
  const [PlaceholPases, setPlaceholPases] = useState([]);
  const [alerta, setAlerta] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [Phone, setPhone] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [EntranteToAdd, setEntranteToAdd] = useState('');
  const [PriceEntranteToAdd, setPriceEntranteToAdd] = useState(0.0);
  const [ExtrasToAdd, setExtrasToAdd] = useState('');
  const [PriceExtrasToAdd, setPriceExtrasToAdd] = useState(0.0);
  const [LocalizacionElegida, setLocalizacionElegida] = useState(0)
  const [horario_elegido, set_horario_elegido] = useState('');
  const [localizacion_elegida, setlocalizacion_elegida] = useState('')
  const [fecha_consulta, setFechaConsulta] = useState(getToday());
  const [paso, setPaso] = useState(1);
  const [canContinue, setContinue] = useState(false);
  const [ListSubscription, setsubscriptionL] = useState([]);
  const [ListSubscription3, setsubscriptionL3] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [primerEntrante, setPrimerentrante] = useState('');
  const [ListLocalizaziones, setListLocalizaziones] = useState([]);
  const [localityP, setLocalityP] = useState('');
  const [Extras, setListadoExtras] = useState([]);
  const [extra, setExtra] = useState('');
  const [Asistentes, setAsistentes] = useState(0);
  const [totalMenu, setTotalMenu] = useState(0.0);
  const [totalExtras, setTotalExtras] = useState(0.0);
  const [OriglistaEntrantes ,setOriglistaEntrantes] = useState([]);
  const [OriglistaExtras, setOriglistaExtras] = useState([]);


    const yearInputRef = useRef(null);
    const cvvInputRef = useRef(null);

    const handleAvanzaPaso = () =>{

      if (canContinue){
        
        if (paso+1!=2) setContinue(false); else setContinue(true);

        if (paso+1 == 1 &&  subscriptions.length>7) setContinue(true);

        if (paso+1==3 && horario_elegido!='') setContinue(true);
  
        if (paso+1==4 && Asistentes>0 && LocalizacionElegida>0) setContinue(true);

        if (paso+1 == 2) setContinue(true);

        if (paso+1==7) {
          history.push({
            pathname: "/"
          });
        }

        setPaso(paso+1);
        
      }
      registerStep(false,'coctel');
    }
  
    const handleRetrocedePaso = () =>{
        var pasoAnterior;
        pasoAnterior = paso-1;

        if (!Pagando){
        if (pasoAnterior == 0){
          setName(nameClient);
          setPhone(phoneClient);
          setEmail(mailClient);
          if (phoneClient.length>8 && nameClient!="" && mailClient.includes('@') && mailClient.includes('.')){
            setContinue(true); 
          } else setContinue(false);
        }
        if (pasoAnterior == 1){
    
        if( subscriptions.length>7 )  setContinue(true); else setContinue(false);
    
        } 
  
        if (pasoAnterior == 3){
          if (horario_elegido!='' && fecha_consulta!='') setContinue(true);
        }
  
        if (pasoAnterior == 4){
          if (Asistentes>0 && LocalizacionElegida>0){
            setContinue(true); 
          } else setContinue(false);
    
        }
  
        setPaso(pasoAnterior)
      }
      }
    
    const changeCardName = (e) => {
      setCardName(e.target.value);
      if (e.target.value!="" && cardNumber.length>15 && month.length>0 && year.length==4 && cvv.length>2) setContinue(true); else setContinue(false);
    }
  
    const changeCardNumber = (e) => {
      var newCardNumber = e.target.value.split(" ").join(""); // remove hyphens
      if (cardName!="" && newCardNumber.length==16 && month.length>0 && year.length==4 && cvv.length>2) setContinue(true); else setContinue(false);
  
      if (isNumber(newCardNumber)) setCardNumber(newCardNumber); else newCardNumber=cardNumber.split(" ").join("");
      if (newCardNumber.length > 0) {
        newCardNumber = newCardNumber.match(new RegExp('.{1,4}', 'g')).join(" ");
      }
      e.target.value = newCardNumber;
    
    }
    
    const changeMonth = (e) => {
      var newMonth = e.target.value;
      if (isNumber(newMonth)) setMonth(newMonth); else {newMonth=month;e.target.value=month;}
      if (newMonth.toString().length == e.target.maxLength){
        yearInputRef.current.focus();
      }
      if (cardName!="" && cardNumber.length==16 && newMonth.length>0 && year.length==4 && cvv.length>2) setContinue(true); else setContinue(false);
    }
    
    const changeYear = (e) => {
      var newYear = e.target.value;
      if (isNumber(newYear)) setYear(newYear); else {newYear=year;e.target.value=year;}
      if (newYear.toString().length == e.target.maxLength){
        cvvInputRef.current.focus();
      }
      if (cardName!="" && cardNumber.length==16 && month.length>0 && newYear.length==4 && cvv.length>2) setContinue(true); else setContinue(false);
    }
    
    const changeCVV = (e) => {
      var newCVV = e.target.value;
      if (isNumber(newCVV)) setCVV(newCVV); else {newCVV=cvv;e.target.value=cvv;}
      if (cardName!="" && cardNumber.length==16 && month.length>0 && year.length==4 && newCVV.length>2) setContinue(true); else setContinue(false);
    }

    
    const handleFecha = (e, fecha) => {

      setFechaConsulta(fecha); 

  
  }   

  const handleHora = (e, horario) => {

    set_horario_elegido(horario); 

    setContinue(true);
  }
  
  const muestraContrato = async (payment_id) =>{

    var documento_a_firmar=
        '<html width="1735" height="1227">' + 
        '<style> html, body { width: 1735px; height: 1227px; background-repeat: no-repeat;}</style>' +
        '<body background="/plantillaQRV3.png" >' +
        '<p>PASES</p>'
        subscriptions.map(cus => {
          documento_a_firmar+= '<p>'+cus.nombre+'<p>';
  
        });
    documento_a_firmar+=
    '<p>EXTRAS</p>'+
    Extras.map(cus => {
      documento_a_firmar+='<p>'+cus.nombre+'<p>';

    });

    var d = new Date();
    var n = d.toLocaleTimeString();


    documento_a_firmar+=
    '<p>CARACTERISTICAS DEL COCTEL</p>'+
    '<p><col>Fecha del coctel <col><col>'+fecha_consulta+'</col></p>'+
    '<p><col>Horario del coctel <col><col>'+horario_elegido+'</col></p>'+
    '<p><col>Lugar del coctel <col><col>'+localizacion_elegida+'</col></p>'+
    '<p><col>Asistentes al coctel <col><col>'+Asistentes+'</col></p>'+
    '<p></p>'+
    '<p>PAGO DE ANTICIPO</p>'+
    '<p><col>Anticipo abonado <col><col>EUR 309.00</col></p>'+
    '<p><col>Identificador del pago <col><col>'+payment_id+'</col></p>'+
    '<p><col>Fecha del abono <col><col>'+getToday()+'</col></p>'+
    '<p><col>Hora del abono <col><col>'+n+'</col></p>'+
    '<p></p>'

    
    const signer = crypto.createSign('RSA-SHA256');
    signer.write(documento_a_firmar);
    signer.end();
    const firma = signer.sign(private_key, 'base64')

    registraEvento(payment_id,firma);

    var a = window.open('', '', 'width=1735, height=1227');
    a.document.write('<html width="1735" height="1227">');
    a.document.write('<style> html, body { width: 1735px; height: 1227px; background-repeat: no-repeat;}</style>')
    a.document.write('<body background="/plantillaQRV3.png" >');
    a.document.write('<p>PASES</p>');
    subscriptions.map(cus => {
      a.document.write('<p>'+cus.nombre+'<p>');
      });
    a.document.write('<p>EXTRAS</p>');
    Extras.map(cus => {
      a.document.write('<p>'+cus.nombre+'<p>');
      });
    a.document.write('<p>CARACTERISTICAS DEL COCTEL</p>');
    a.document.write('<p><col>Fecha del coctel <col><col>'+fecha_consulta+'</col></p>');
    a.document.write('<p><col>Horario del coctel <col><col>'+horario_elegido+'</col></p>');
    a.document.write('<p><col>Lugar del coctel <col><col>'+localizacion_elegida+'</col></p>');
    a.document.write('<p><col>Asistentes al coctel <col><col>'+Asistentes+'</col></p>');
    a.document.write('<p></p>');
    a.document.write('<p>PAGO DE ANTICIPO</p>');
    a.document.write('<p><col>Anticipo abonado <col><col>EUR 309.00</col></p>');
    a.document.write('<p><col>Identificador del pago <col><col>'+payment_id+'</col></p>');
    a.document.write('<p><col>Fecha del abono <col><col>'+getToday()+'</col></p>');
    a.document.write('<p><col>Hora del abono <col><col>'+n+'</col></p>');
    a.document.write('<p></p>');
    a.document.write('<p>FIRMA</p>');
    a.document.write('<p>'+firma+'</p>');
    a.document.write('</body></html>');
    a.document.close();
    //
    setTimeout(function() { 
      a.print();
      }.bind(this), 2000)

  }

  const registraEvento = async (payment_id, firma) => {

    var Nombre = '';
    var Correo = '';
    var Telef = ''
    if (Name=="") Nombre = nameClient; else Nombre = Name;
    if (Email=="") Correo = mailClient; else Correo = Email;
    if (Phone=="") Telef = phoneClient; else Telef = Phone;

    var listaPasesMarcados = "{'pases':[";

    subscriptions.map(client => {listaPasesMarcados+="'"+client.nombre+"',"});

    listaPasesMarcados+="]}";


    var listaExtrasMarcados = "{'extras':[";

    Extras.map(client => {listaExtrasMarcados+="'"+client.nombre+"',"});

    listaExtrasMarcados+="]}";


    const result = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/register_event_agenda?date=${fecha_consulta}&hour=${horario_elegido}&seated=${Asistentes}&standed=0&localization=${LocalizacionElegida}&pay_id=${payment_id}&nombre=${Nombre}&email=${Correo}&telefono=${Telef}&sesion=${sesion}&tipo_evento=coctel&firma=${firma}&pases=${listaPasesMarcados}&extras=${listaExtrasMarcados}&entrantes={}&primeros={}`);

  }

  const payAnticipo = async () => {
    setShowError(false);
    setContinue(false);

    var Nombre = '';
    var Correo = '';
    var Telef = ''
    if (Name=="") Nombre = nameClient; else Nombre = Name;
    if (Email=="") Correo = mailClient; else Correo = Email;
    if (Phone=="") Telef = phoneClient; else Telef = Phone;

    setPagando(true);
    const result = await ObexProjectsRequestHandler.get(`/ws/stripe_interface.py/create_payment_method?username=${Nombre}&email=${Correo}&card_number=${cardNumber}&card_name=${cardName}&month_exp=${month}&year_exp=${year}&cvv=${cvv}&amount=300&license=anticipo`);
    console.log("RESULT DATA");
    console.log(result["status"]);
    try{
      if ('next_action' in result){
        console.log("PAGO PENDIENTE");
        setAlerta(true);
        open(result['next_action']["use_stripe_sdk"]["stripe_js"]);

        var completed = false;
        const payload2 = {
          payment_id: result["payment_id"].toString(),
        }
        console.log(payload2);
  
        while (!completed){
          await sleep(5000);
          console.log(`comprobando pago ${result["payment_id"]}`);
  
          try{
          const resulta = await ObexProjectsRequestHandler.get(`/ws/stripe_interface.py/check_card_payment?payment_id=${result["payment_id"]}`);
          console.log(resulta);
  
          if ('status' in resulta){
            if ('completed' in resulta){
              if (resulta['completed']) {
                completed = true;
                if (resulta["total_captured"]==30000) {
                  console.log("PAGO RECIBIDO");
                setPaso(paso+1);
                console.log(resulta);
                document.getElementById("slp").style.display = "none";
                muestraContrato(result["payment_id"]);

                
                } else {
                  setShowError(true);
                  SeterrorMessage("El pago no ha podido ser realizado, consulte con su entidad financiera.");
                  setPagando(false);
                  document.getElementById("slp").style.display = "none";
                }
                setContinue(true)
              }
              
            } else {
              setShowError(true);
              SeterrorMessage("El pago no ha podido ser realizado, consulte con su entidad financiera.");
              setPagando(false);
              document.getElementById("slp").style.display = "none";
            }
          } else {
            setShowError(true);
            SeterrorMessage("El pago no ha podido ser realizado, consulte con su entidad financiera.");
            setPagando(false);
            document.getElementById("slp").style.display = "none";
          }
          } catch{

          }
  
  
  
        }


        
      } else {
        console.log("NO ES PAGO CON AUTORIZACION");
        console.log(result);
        if ('payment_id' in result){
          console.log("PAGO EXITOSO");

          setPaso(paso+1);
          setContinue(true)
          console.log(result);
          document.getElementById("slp").style.display = "none";
          muestraContrato(result["payment_id"]);
        } else {
          console.log("PAGO FALLIDO");
          console.log(result["message"])
          if (result["message"].toString().includes('rolled back') || result["message"].toString().includes("AdminShutdown")) {
            console.log("ERROR DE SISTEMA");
            setContinue(false)
            payAnticipo();
          } else {
            setContinue(true)
            setShowError(true);
            SeterrorMessage("El pago no ha podido ser realizado, consulte con su entidad financiera.");
            setPagando(false);
            document.getElementById("slp").style.display = "none";
          }

        }
      }
    } catch {      
        const { success, data, message } = result;
        if (success){
          if (result.toString().includes('created')){
            console.log("PAGO REALIZADO");
            setSuccess("Pago realizado con éxito");
            setLoading(false);
            console.log(result);
            document.getElementById("slp").style.display = "none";
            muestraContrato(result["payment_id"]);
          }
      }else {
        setError(message);
        setLoading(false);
        setPagando(false);
        document.getElementById("slp").style.display = "none";
      } 
    }  
    

  }

  const avanzarMenu = async () => {
    if (paso==1){
      var totalentrantes = 0.0;
      subscriptions.map(cus => {
        totalentrantes+=cus.precio;

      });
      totalentrantes-=16;

      var totaextras = 0.0;
      Extras.map(cus => {
        totaextras+=cus.precio;

      });
      

      setTotalExtras(totaextras);
      setTotalMenu(totalentrantes+32);
    }
    if (paso+1==5) setContinue(false);
    
    if (paso == 5){
      document.getElementById("slp").style.display = "initial";
      payAnticipo();


    } else handleAvanzaPaso();
    
  }
  const getSubscriptions = async () => {
    document.getElementById("loaderPases").style.display = "initial";
    const result = await ObexProjectsRequestHandler.get('/ws/util.py/get_items_presupuestos_eventos?categoria=pases');
    document.getElementById("loaderPases").style.display = "none";
    
    const { status, listado } = result;
    if (status) {
      
      setPrimerentrante(listado[0].nombre)
      setEntranteToAdd(listado[0].nombre);
      setPriceEntranteToAdd(listado[0].precio);

      setOriglistaEntrantes(listado);

      const subscriptionList = listado.map(c => <><option value={c.id} selected={localityP == c.id }>{c.nombre}</option></>
      );
      setsubscriptionL(subscriptionList);

    
    
    } else {
        console.error('ALGO HA IDO MAL')
    }

    var placeholders = 0;
    var PlaceholdersText = [];
    if (subscriptions.length<8) {
      placeholders=(8-subscriptions.length);
      for (var conta=0;conta < placeholders;conta++){
        PlaceholdersText.push({'id':conta,'nombre':'Elige una opción'})
      }
      setPlaceholPases(PlaceholdersText);
    } else {
      setPlaceholPases([]);
    }

  }
  const getLocalizaciones = async (fecha, horario, asistentes) => {
    setAgenda([]);
    setContinue(false);
    var hora = horario.toLowerCase();

    var seated_reserve = asistentes;
    var standing_reserve = 0
    document.getElementById("loaderPlaces").style.display = "initial";
    const result = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/get_agenda_date?date=${fecha}&hour=${hora}&seated_reserve=${seated_reserve}&standing_reserve=${standing_reserve}`);
    document.getElementById("loaderPlaces").style.display = "none";
    const { status, agenda } = result;
    if (status) {

      if (agenda.length>0)
      {
        setAgenda(agenda);
        const Localiza = agenda.map(
            c => <><option value={c.id} selected={localityP == c.id }>{c.nombre}</option></>
          );
        setListLocalizaziones(Localiza);
        
      }
      else {
        setAgenda([]);
        setListLocalizaziones([]);
        setLocalizacionElegida(0);
        setContinue(false);
      }



    
    } else {
        console.error('ALGO HA IDO MAL')
        setContinue(false);
    }
  }


  const getExtras = async () => {
    document.getElementById("loaderExtras").style.display = "initial";
    const result = await ObexProjectsRequestHandler.get('/ws/util.py/get_items_presupuestos_eventos?categoria=extras');
    document.getElementById("loaderExtras").style.display = "none";
    const { status, listado } = result;
    if (status) {
      
      setExtra(listado[0].nombre+" . . . EUR"+listado[0].precio+".00")
      setExtrasToAdd(listado[0].nombre);
      setPriceExtrasToAdd(listado[0].precio);

      setOriglistaExtras(listado);

      const subscriptionList3 = listado.map(c => <><option value={c.id} selected={localityP == c.id }>{c.nombre} . . . EUR {c.precio}.00</option></>
      );
      setsubscriptionL3(subscriptionList3);

    
    
    } else {
        console.error('ALGO HA IDO MAL')
    }
  }


  const getExtrasUpdate = async () => {

    //const result = await ObexProjectsRequestHandler.get('/ws/util.py/get_items_presupuestos_eventos?categoria=extras');

    //const { status, listado } = result;
    //if (status) {
      
    //  setOriglistaExtras(listado);

    //  const subscriptionList3 = listado.map(c => <><option value={c.id} selected={localityP == c.id }>{c.nombre}</option></>
    //  );

    
    
    //} else {
    //    console.error('ALGO HA IDO MAL')
    //}

    var placeholders = 0;
    var PlaceholdersText = [];
    if (subscriptions.length<8) {
      placeholders=(8-subscriptions.length);
      for (var conta=0;conta < placeholders;conta++){
        PlaceholdersText.push({'id':conta,'nombre':'Elige una opción'})
      }
      setPlaceholPases(PlaceholdersText);
    } else {
      setPlaceholPases([]);
    }


  }


  const verificaContinue = async (e) => {

    if (paso == 0){
      if (Phone.length>8 && Name!="" && Email.includes('@') && Email.includes('.')){
        setContinue(true); 
      } else setContinue(false);
    }
    if (paso == 1){

    if( subscriptions.length>7)  setContinue(true); else setContinue(false);

    } 
    if (paso == 4){
      if (Asistentes>0 && LocalizacionElegida>0){
        setContinue(true); 
      } else setContinue(false);

    }

  }




  const handleName = (e) => {
    const { value } = e.target;
    setName(value);
    verificaContinue(e);
    if (Phone.toString().length>8 && value!="" && Email.includes('@') && Email.includes('.')){
      setContinue(true);} else setContinue(false);
  }

  const handleEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
    verificaContinue(e);

    if (Phone.toString().length>8 && Name!="" && value.includes('@') && value.includes('.')){
      setContinue(true);} else setContinue(false);
  }

  const handlePhone = (e) => {
    const { value } = e.target;
    if (value.length<10 && isNumber(value)) setPhone(value); else e.target.value=Phone;
    verificaContinue(e);
    if (value.toString().length>8 && Name!="" && Email.includes('@') && Email.includes('.')){
      setContinue(true);} else setContinue(false);
  }


  const setEntrantesId = (event) => {

    OriglistaEntrantes.map(cus => {
      
      if (cus.id == event.target.value) 
      {
        setEntranteToAdd(cus.nombre);

        setPriceEntranteToAdd(cus.precio);

      }
    })
  };



  const setExtrasId = (event) => {
    OriglistaExtras.map(cus => {
      if (cus.id == event.target.value) 
      {
        setExtrasToAdd(cus.nombre);

        setPriceEntranteToAdd(cus.precio);

 
      }
    })
  };
  const handleLocalizacionElegida = (e) =>{
   
    setLocalizacionElegida(e.id);
    setlocalizacion_elegida(e.nombre);
    verificaContinue(e);
    setContinue(true);
  }

  const handleAsistentes = (e) => {

    const { value } = e.target;
    setAsistentes(value);
    getLocalizaciones(fecha_consulta,horario_elegido,value);

  }

  const handleEraseEntrante = async (event, client) => {

      event.preventDefault();
      event.stopPropagation();

      var EntrantesTemp = [];
      subscriptions.map(cus => {
        if (cus.id == client.id) 
        {

        } else {
          EntrantesTemp.push(cus);
        }
      })
      setSubscriptions(EntrantesTemp);

      verificaContinue(event);
      if (EntrantesTemp.length<8) setContinue(false);


      var placeholders = 0;
      var PlaceholdersText = [];
      if (EntrantesTemp.length<8) {
        placeholders=(8-(EntrantesTemp.length));
        for (var conta=0;conta < placeholders;conta++){
          PlaceholdersText.push({'id':conta,'nombre':'Elige una opción'})
        }
        setPlaceholPases(PlaceholdersText);
      } else {
        setPlaceholPases([]);
      }

                  ////

                  var ListaTemp = "";

                  EntrantesTemp.map (opc=>{ ListaTemp+=opc.nombre; })
            
                  var OpcionesEntrantes = []
            
                    var nombre = '';
                    var precio = 0.0;
                    var notAdd =true;
                    OriglistaEntrantes.map (cus => {
                    if (ListaTemp.includes(cus.nombre)) {notAdd=true;} else {
                      if (notAdd){
                        nombre = cus.nombre;
                        precio = cus.precio;
                        notAdd = false;
                      }
                      OpcionesEntrantes.push(<><option value={cus.id} selected={localityP == cus.id }>{cus.nombre}</option></>);
                    }
                  });
                  if (nombre==""){nombre = OriglistaEntrantes[0].nombre;precio=OriglistaEntrantes[0].precio};
                  setsubscriptionL(OpcionesEntrantes);
                  setPrimerentrante(nombre)
                  setEntranteToAdd(nombre);
                  setPriceEntranteToAdd(precio);
            
                  /////

    }



  const handleEraseExtra = async (event, client) => {

    event.preventDefault();
    event.stopPropagation();

    var ExtraTemp = [];
    Extras.map(cus => {
      if (cus.id == client.id) 
      {

      } else {
        ExtraTemp.push(cus);
      }
    })
    setListadoExtras(ExtraTemp);
    verificaContinue(event);

                ////

                var ListaTemp = "";

                ExtraTemp.map (opc=>{ ListaTemp+=opc.nombre; })
          
                var OpcionesExtras = []
          
                  var nombre = '';
                  var precio = 0.0;
                  var notAdd =false;
                  OriglistaExtras.map (cus => {
                  if (ListaTemp.includes(cus.nombre)) {notAdd=true;} else {
                    if (notAdd){
                      notAdd = false;
                      nombre = cus.nombre;
                      precio = cus.precio;
                    }
                    OpcionesExtras.push(<><option value={cus.id} selected={localityP == cus.id }>{cus.nombre} . . . EUR {cus.precio}.00</option></>);
                  }
                });
                if (nombre==""){nombre = OriglistaExtras[0].nombre;precio=OriglistaExtras[0].precio};
                setsubscriptionL3(OpcionesExtras);
                setExtra(nombre+" . . . EUR "+precio+".00");
                setExtrasToAdd(nombre);
                setPriceExtrasToAdd(precio);
          
                /////


  }

  const handleAddEntrante = (e) =>{
      var EntrantesTemp = subscriptions;
      var id = new Date();
      var ide = `${id.getHours()}${id.getMinutes()}${id.getSeconds()}${id.getMilliseconds()}`
      var elemento = {
        'id': ide,
        'nombre': EntranteToAdd,
        'precio': PriceEntranteToAdd
      };
      
      EntrantesTemp.push(elemento);
      setSubscriptions(EntrantesTemp);

            ////

            var ListaTemp = "";

            EntrantesTemp.map (opc=>{ ListaTemp+=opc.nombre; })
      
            var OpcionesEntrantes = []
      
              var nombre = '';
              var precio = 0.0;
              var notAdd =false;
              OriglistaEntrantes.map (cus => {
              if (ListaTemp.includes(cus.nombre)) {notAdd=true;} else {
                if (notAdd){
                  notAdd = false;
                  nombre = cus.nombre;
                  precio = cus.precio;
                }
                OpcionesEntrantes.push(<><option value={cus.id} selected={localityP == cus.id }>{cus.nombre}</option></>);
              }
            });
      
            setsubscriptionL(OpcionesEntrantes);
            setPrimerentrante(nombre)
            setEntranteToAdd(nombre);
            setPriceEntranteToAdd(precio);
      
            /////
      

      verificaContinue(e);
      getExtrasUpdate();
    }

  const handleAddExtra = (e) =>{
    var ExtrasTemp = Extras;
    var id = new Date();
    var ide = `${id.getHours()}${id.getMinutes()}${id.getSeconds()}${id.getMilliseconds()}`
    var elemento = {
      'id': ide,
      'nombre': ExtrasToAdd,
      'precio': PriceExtrasToAdd
    };
    
    ExtrasTemp.push(elemento);
    setListadoExtras(ExtrasTemp);

    
              ////

              var ListaTemp = "";

              ExtrasTemp.map (opc=>{ ListaTemp+=opc.nombre; })
        
              var OpcionesExtras = []
        
                var nombre = '';
                var precio = 0.0;
                var notAdd =false;
                OriglistaExtras.map (cus => {
                if (ListaTemp.includes(cus.nombre)) {notAdd=true;} else {
                  if (notAdd){
                    notAdd = false;
                    nombre = cus.nombre;
                    precio = cus.precio;
                  }
                  OpcionesExtras.push(<><option value={cus.id} selected={localityP == cus.id }>{cus.nombre} . . . EUR {cus.precio}.00</option></>);
                }
              });
        
              setsubscriptionL3(OpcionesExtras);
              setExtra(nombre+" . . . EUR "+precio+".00")
              setExtrasToAdd(nombre);
              setPriceExtrasToAdd(precio);
        
              /////
              

    verificaContinue(e);
    getExtrasUpdate();
  }
  const registerStep = async (inicio,tipoEvento) => {

    var Nombre = '';
    var Correo = '';
    var Telef = ''
    if (Name=="") Nombre = nameClient; else Nombre = Name;
    if (Email=="") Correo = mailClient; else Correo = Email;
    if (Phone=="") Telef = phoneClient; else Telef = Phone;

    var listaPasesMarcados = "{'pases':[";

    subscriptions.map(client => {listaPasesMarcados+="'"+client.nombre+"',"});

    listaPasesMarcados+="]}";


    var listaExtrasMarcados = "{'extras':[";

    Extras.map(client => {listaExtrasMarcados+="'"+client.nombre+"',"});

    listaExtrasMarcados+="]}";

    var d = new Date();
    var n = d.toLocaleTimeString();

    const result = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/register_event_agenda_incompleto?id_canal=1&codigo_pais=ES&name=${Nombre}&email=${Correo}&phone=${Telef}&tipo_reserva=${tipoEvento}&entrantes=${listaPasesMarcados}&pases={}&primeros={}&extras=${listaExtrasMarcados}&reserva_sentado=${Asistentes}0&reserva_pie=0&fecha_reserva=${fecha_consulta}&franja_horaria=${horario_elegido}&id_localizacion=${LocalizacionElegida}&sesion=${sesion}&iniciosesion=${inicio}&fecha_registro=${getToday()}&hora_registro=${n}`);


  }
  useEffect(() => {
    getSubscriptions();
    getExtras();
    registerStep(false,'coctel');
  }, [])

  return(
    <Container fluid className="text-center d-flex flex-column min-vh-100" >
      <Row className="justify-content-center min-vh-100" >
      <div className="min-vh-100" id="slp" style={{ height: '100%', width: '100%', position: 'fixed', backgroundColor: '#00000070', zIndex: 9999, opacity: '100%', justifyContent: 'center' ,display:'none'}}>
          <div className='loader' style={{ zIndex: 99999, opacity: '100%', marginTop: '20%' , color:'orange'}}></div>
      </div>

        <div className="rounded px-5 pb-4" style={{ minWidth : '400px', maxWidth:'800px', backgroundColor: "white"}}>
          <Row className="justify-content-center" 
                style={
                  {
                    paddingTop:"20px", 
                    paddingBottom: "20px", 
                    paddingLeft: 0, 
                    paddingRight: 0,
                    marginRight: "-60px",
                    marginLeft: "-60px",
                    marginBottom: "3px"
                    }}

                    >
            <Col >
              <Image
                style={{height:'200px'}} 
                src={Logo2} />
            </Col>
          </Row>


          <Row className="justify-content-center" 
              style={
                {
                  paddingTop:"20px", 
                  paddingBottom: "20px", 
                  paddingLeft: 0, 
                  paddingRight: 0,
                  marginRight: "-60px",
                  marginLeft: "-60px",
                  marginBottom: "3px"
                  }}

                  >
          <Col >
          {paso == 1 ?
            <Image
              style={{height:'20px'}} 
              src={pasos3} /> :
              <></>
          }
          {paso == 2 ?
            <Image
              style={{height:'20px'}} 
              src={pasos4} /> :
              <></>
          }
          {paso == 3 || paso == 4?
            <Image
              style={{height:'20px'}} 
              src={pasos5} /> :
              <></>
          }
          {paso == 5 ?
            <Image
              style={{height:'20px'}} 
              src={pasos6} /> :
              <></>
          }
          </Col>
        </Row>

        {/**************************************************************************************** Datos de contacto */}

        { paso==0 ?
      <><div style={{ marginTop: '40px', fontSize: 'large' }}>
            <a style={{color:'gray'}}>Bienvenido al</a> <a style={{fontStyle:'italic'}}>configurador de eventos y cotizador digital</a><a style={{color:'gray'}}> de </a>
          </div>
          <div style={{ fontSize: 'large', color:'gray'}}>La Gloria de Santo Domingo</div>
          <div style={{ fontSize: 'large', marginTop:'10px'}}>¡Queremos Conocerte!</div>
          <Form style={{ marginTop: '40px', marginLeft:'14%', marginRight:'14%' }}>
              <Form.Group controlId="formBasicEmail">
                <Row style={{borderStyle:'solid', borderWidth:'1px', borderRadius:'5px', width:'100%', marginLeft:'0px', borderColor:'#ced4da'}}>
                  <Col style={{maxWidth:'25px', display:'contents'}}><Image src={iconoNombre} style={{height:'23px', width:'20px', marginTop:'6px', marginLeft:'10px'}} /></Col>
                  <Col style={{marginLeft:'-15px'}}><Form.Control style={{borderStyle:'hidden', boxShadow:'none'}} onChange={handleName} required type="string" placeholder="Nombre" value={Name}/></Col>
                
                </Row>
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
              <Row style={{borderStyle:'solid', borderWidth:'1px', borderRadius:'5px', width:'100%', marginLeft:'0px', borderColor:'#ced4da'}}>
              <Col style={{maxWidth:'25px', display:'contents'}}><Image src={iconoCorreo} style={{height:'17px', width:'20px', marginTop:'10px', marginLeft:'10px'}} /></Col>
              <Col style={{marginLeft:'-15px'}}><Form.Control style={{borderStyle:'hidden', boxShadow:'none'}} onChange={handleEmail} required type="email" placeholder="Correo electrónico" value={Email}/></Col>
              </Row>
              </Form.Group>
              <Form.Group controlId="formBasicPhone">
              <Row style={{borderStyle:'solid', borderWidth:'1px', borderRadius:'5px', width:'100%', marginLeft:'0px', borderColor:'#ced4da'}}>
              <Col style={{maxWidth:'25px', display:'contents'}}><Image src={iconoTelefono} style={{height:'23px', width:'15px', marginTop:'6px', marginLeft:'12px', marginRight:'3px'}} /></Col>
              <Col style={{marginLeft:'-15px'}}><Form.Control style={{borderStyle:'hidden', boxShadow:'none'}} onChange={handlePhone} required type="phone" placeholder="Teléfono de contacto" value={Phone} /></Col>
              </Row>
              </Form.Group>
            </Form></>
            :
            <></>

              }

        {/*********************************************************************************** ************************/}

        {/*********************************************************************************** Datos del Presuepuesto */}



        { paso==1 ?
          <><><>


            {/****** Entrantes ******************************************************************************************************************+ */}


            <span style={{ marginTop: '40px',  marginBottom: '20px', fontSize:'17px', fontWeight:'bold' }}><h5>La composición del menú es:</h5></span>
                <div style={{backgroundColor:'lightgray', height:'1px', width:'100%', marginBottom:'60px'}}></div>
              
              
              <Row style={{backgroundColor:'#f3f4f4', borderColor:'#dfdfdf',borderWidth:'1px',borderStyle:'solid'}}>

                <Col>

                <Row className="justify-content-center" 
              style={
                {
                  marginTop:'-15px'
                  }}

                  >
          <Col >

            <Image
              style={{height:'30px'}} 
              src={uno} /> 

          </Col>
        </Row>


                <Row style={{justifyContent:'center', marginTop:'30px'}}><h5>Selecciona tus pases</h5></Row>
                <Row style={{justifyContent:'center', marginTop:'-10px', color:'gray', fontSize:'large'}}>Elige ocho opciones de nuestro menú.</Row>
              
              
              <Row className="planSelector" style={{marginLeft:'2%',backgroundColor:'#8d8d8d', justifyContent:'center',marginTop:'20px',color:'white', height:'40px',width:'96%', borderRadius:'5px'}}>
              
                <Col style={{width:'90%', }}>
                  <Form className="obex-form" >

                    <Form.Group controlId="formBasicEntrante">
                      <Form.Control className="planSelectorControl" style={{color:'white', backgroundColor:'#8d8d8d', borderStyle:'none'}} required as="select" placeholder='Plan' custom onChange={(event) => { setEntrantesId(event); } }>
                        <option value="" selected disabled hidden>{primerEntrante}</option>
                        {ListSubscription}
                      </Form.Control>
                    </Form.Group>

                  </Form>
                </Col>
                <Col style={{width:'0%', flex:'inherit'}}>
                  <button className="btn-transparent" style={{ float: 'right', marginTop: '8px' }}><FontAwesomeIcon icon={faPlusCircle} onClick={handleAddEntrante} /> </button>
                </Col>
              </Row>
              <Row style={{ backgroundColor: '#f3f4f4', minHeight: '515px' }}>

                <Row style={{marginTop: '20px', marginBottom: '80px', minHeight: '485px', marginLeft: '10px', marginRight: '10px' }}>
              
                    {subscriptions.map(client => {
                      return (
                        <Row  style={{ backgroundColor:'white', marginLeft:'2.5%', width:'95%', minHeight:'40px', maxHeight:'55px',paddingTop:'10px', textAlign:'center', overflow:'hidden', borderColor:'lightgray', borderStyle:'solid', borderWidth:'1px', borderRadius:'5px', marginBottom:'10px' }}>
                          <Col style={{width:'90%', }}>
                            <h6>{client.nombre}</h6>
                          </Col>
                          <Col style={{width:'0%', flex:'inherit', marginRight:'20px'}}>
                            
                                <Button style={{color:'#aaaaaa'}} onClick={(event) => handleEraseEntrante(event, client)} type="submit" className="btn-transparent">
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>

                          </Col>
                        </Row>
                      );
                    }
                    )}

                                  
                    {PlaceholPases.map(client => {
                      return (
                        <Row  style={{ fontSize:'15px',color:'lightgray',backgroundColor:'white', marginLeft:'2.5%', width:'95%', minHeight:'40px', maxHeight:'55px',paddingTop:'10px', textAlign:'center', overflow:'hidden', borderColor:'lightgray', borderStyle:'solid', borderWidth:'1px', borderRadius:'5px', marginBottom:'10px' }}>
                          <Col style={{width:'90%', }}>
                            {client.nombre}
                          </Col>
                        </Row>
                      );
                    }
                    )}

                    <Col style={{justifyContent:'center', display:'flex'}}>
                      <Row id="loaderPases" className="loader" style={{display:'none' ,marginTop:'140px' ,marginBottom:'140px' , color:'orange'}}></Row>
                    </Col>
        
                  <div style={{color:'gray', marginLeft:'10%', marginRight:'10%'}}>
                  Si usted desea añadir un pase (más de 8), cada pase tendrá un costo extra de 2 EUR..
                  </div>
                  <div style={{color:'gray', marginLeft:'10%', marginRight:'10%', marginTop:'0px'}}>
                  Cada uno de los pases seleccionados que se acompañan de asteriscos, tienen un costo extra de 0,50 EUR por asterisco.
                  </div>
                </Row>


              </Row>

              </Col>
              </Row>
            </><>



          
                
{/* ************** Selector de Plato Principal *******************************************************************************/}



         



            {/*****Extras ********************************************************************************************** */}

                
              </></><>

              <div style={{backgroundColor:'white', height:'20px', width:'100%', marginBottom:'60px'}}></div>
              <Row style={{backgroundColor:'#f3f4f4', borderColor:'#dfdfdf',borderWidth:'1px',borderStyle:'solid'}}>
              <Col>
                <Row className="justify-content-center" style={{ marginTop:'-15px' }} >
                  <Col>
                    <Image style={{height:'30px'}} src={tres} /> 
                  </Col>
                </Row>
                <Row style={{justifyContent:'center', marginTop:'30px'}}><h5>Selecciona los servicios extras</h5></Row>
                <Row style={{justifyContent:'center', marginTop:'-10px', color:'gray', fontSize:'large'}}></Row>
                <Row className="planSelector" style={{marginLeft:'2%',backgroundColor:'#8d8d8d', justifyContent:'center',marginTop:'20px',color:'white', height:'40px',width:'96%', borderRadius:'5px'}}>
                <Col style={{width:'90%', }}>
                  <Form className="obex-form" >
                    <Form.Group controlId="formBasicEntrante">
                      <Form.Control className="planSelectorControl" style={{color:'white', backgroundColor:'#8d8d8d', borderStyle:'none'}} required as="select" placeholder='Plan' custom onChange={(event) => { setExtrasId(event); } }>
                        <option value="" selected disabled hidden>{extra}</option>
                        {ListSubscription3}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </Col>
                <Col style={{width:'0%', flex:'inherit'}}>
                  <button className="btn-transparent" style={{ float: 'right', marginTop: '8px' }}><FontAwesomeIcon icon={faPlusCircle} onClick={handleAddExtra} /> </button>
                </Col>
                </Row>
                <Row style={{ backgroundColor: '#f3f4f4', minHeight: '80px' }}>
                <Row style={{ width: '100%', marginTop: '20px', marginBottom: '80px', minHeight: '60px', marginLeft: '10px', marginRight: '10px' }}>
                  {Extras.map(client => {
                    return (
                      <Row  style={{ backgroundColor:'white', marginLeft:'2.5%', width:'95%', minHeight:'40px', maxHeight:'40px',paddingTop:'10px', textAlign:'center', overflow:'hidden', borderColor:'lightgray', borderStyle:'solid', borderWidth:'1px', borderRadius:'5px', marginBottom:'10px' }}>
                        <Col style={{width:'80%', }}>
                          <h6>{client.nombre}</h6>
                        </Col>
                        <Col style={{width:'10%', }}>
                          <h6>EUR {client.precio}.00</h6>
                        </Col>
                        <Col style={{width:'0%', flex:'inherit', marginRight:'20px'}}>
                          <Button style={{color:'#aaaaaa'}} onClick={(event) => handleEraseExtra(event, client)} type="submit" className="btn-transparent">
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </Col>
                      </Row>
                    );
                  }
                  )}

                  <Col style={{justifyContent:'center', display:'flex'}}>
                    <Row id="loaderExtras" className="loader" style={{display:'none' ,marginTop:'30px' , color:'orange'}}></Row>
                  </Col>


                </Row>
                </Row>
              </Col>
              </Row>


{/*** RESTO DE TEXTOS */}


<div style={{backgroundColor:'white', height:'20px', width:'100%', marginBottom:'60px'}}></div>
<Row style={{backgroundColor:'#f3f4f4', borderColor:'#dfdfdf',borderWidth:'1px',borderStyle:'solid'}}>
  <Col>
    <Row className="justify-content-center" style={{marginTop:'-15px'}}>
      <Col >
        <Image style={{height:'30px'}} src={cuatro} /> 
      </Col>
    </Row>
  <Row style={{justifyContent:'center', marginTop:'30px'}}><h5>Degustación de postres</h5></Row>
  <Row style={{justifyContent:'center', marginTop:'-10px',marginBottom:'30px', color:'gray', fontSize:'large'}}>Elaboración especial de la Gloria de Santo Domingo.</Row>
  </Col>
</Row>


<div style={{backgroundColor:'white', height:'20px', width:'100%', marginBottom:'60px'}}></div>
<Row style={{backgroundColor:'#f3f4f4', borderColor:'#dfdfdf',borderWidth:'1px',borderStyle:'solid'}}>
  <Col>
    <Row className="justify-content-center" style={{marginTop:'-15px'}}>
      <Col >
        <Image style={{height:'30px'}} src={cinco} /> 
      </Col>
    </Row>
  <Row style={{justifyContent:'center', marginTop:'30px'}}><h5>Cerveza de grifo, vino tinto y blanco, refrescos y agua</h5></Row>
  <Row style={{justifyContent:'center', marginTop:'-10px',marginBottom:'30px', color:'gray', fontSize:'large'}}>La bebida se sirve en formato de barra libre y se inicia con el servicio de entrantes y finaliza con el postre.</Row>
  </Col>
</Row>


{/*** FINAL DE TEXTOS */}

              </></>
          
          :
          <></>
        }

{ paso==2 ?
          <><><div>
            <Row style={{width:'899px'}}></Row>
              <Row className="mb-3" style={{ marginTop:'40px'}}><Col><h5>RESUMEN DE SU CONTRATACIÓN</h5>
              <Row style={{backgroundColor:'#f3f4f4', borderColor:'#dfdfdf',borderWidth:'1px',borderStyle:'solid', marginTop:'20px'}}></Row>
              </Col></Row>

              <Row className="justify-content-center" style={{marginTop:'25px',marginBottom:'25px'}}>
                <Col>
                  <Image style={{height:'30px'}} src={uno} /> 
                </Col>
              </Row>

              <Row className="mb-3" style={{  }}><Col><h5>PASES</h5></Col></Row>
              {subscriptions.map(client => {
                return (
                  <Row className="mb-3" style={{  }}>
                    <Col className="">
                      <span><h6>{client.nombre}</h6></span>
                    </Col>
                  </Row>
                );

              })}

              {Extras.length > 0 ?
              
              <><Row className="justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>
                  <Col>
                    <Image style={{ height: '30px' }} src={tres} />
                  </Col>
                </Row><Row className="mb-3" style={{}}><Col><h5>SERVICIOS EXTRA</h5></Col></Row></> : <></>}
              {Extras.map(client => {
                return (
                  <Row className="mb-3" style={{  }}>
                    <Col className="">
                      <span><h6>{client.nombre}</h6></span>
                    </Col>
                  </Row>
                );

              })}<></>



              <Row className="justify-content-center" style={{marginTop:'25px',marginBottom:'25px'}}>
                <Col>
                {Extras.length > 0 ?
                  <Image style={{height:'30px'}} src={tres} /> :
                  <Image style={{height:'30px'}} src={dos} />
                }
                </Col>
              </Row>
              <Row className="mb-3" style={{}}><Col><h5>POSTRES</h5></Col></Row>
              <Row className="mb-3" style={{  }}>
                <Col className="">
                  <span><h6>Degustación de postres de nuestra elaboración.</h6></span>
                </Col>
              </Row>


              <Row className="justify-content-center" style={{marginTop:'25px',marginBottom:'25px'}}>
                <Col>
                {Extras.length > 0 ?
                  <Image style={{height:'30px'}} src={cuatro} /> :
                  <Image style={{height:'30px'}} src={tres} />
                }
                </Col>
              </Row>
              <Row className="mb-3" style={{}}><Col><h5>BEBIDA</h5></Col></Row>
              <Row className="mb-3" style={{  }}>
                <Col className="">
                  <span><h6>Cerveza de grifo, vino tinto y blanco, refrescos y agua.</h6></span>
                </Col>
              </Row>


            </div>
            

            <div style={{backgroundColor:'white', height:'20px', width:'100%', marginBottom:'60px'}}></div>
            <Row style={{backgroundColor:'#f3f4f4', borderColor:'#dfdfdf',borderWidth:'1px',borderStyle:'solid'}}>
              <Col>

                <Row style={{justifyContent:'init', marginTop:'30px',fontSize:'medium'}}>
                  <Col style={{textAlign:'initial'}}>Precio del menú</Col>
                  <Col style={{textAlign:'end'}}>EUR {totalMenu}.00</Col>
                </Row>
                <Row style={{justifyContent:'init', marginTop:'-5px',marginBottom:'30px', fontSize:'medium'}}>
                  <Col style={{textAlign:'initial'}}>Precio de los servicios extra.</Col>
                  <Col style={{textAlign:'end'}}>EUR {totalExtras}.00</Col>
                </Row>
              </Col>
            </Row>

            </>

            </>
          :
          <></>
        }

{ paso==3 ?
        <>
        <Row style={{width:'899px'}}></Row>
        <Row style={{justifyContent:'center', color:'#8d8d8d', fontSize:'15px' , marginBottom:'20px'}}>Selecciona la <span style={{color:'#626262', fontSize:'15px', fontStyle:'italic', marginLeft:'5px', marginRight:'5px'}}> fecha </span> <span style={{color:'#8d8d8d', fontSize:'15px'}}> para tu evento</span></Row>
        <Row style={{justifyContent:'center'}}>
        <Calendar
          onChange={(event)=>{
            var ff = new Date(event.getTime() - (event.getTimezoneOffset() * 60000 ));
            
            var fecha_temp = ff.toISOString().split('T')[0];
            handleFecha(event, fecha_temp);
            onChangeCalendar(event)}
            
          }
          value={valueCalendar}
          
          />

        </Row>
 
        <Row style={{justifyContent:'center', color:'#8d8d8d', fontSize:'15px' , marginBottom:'20px', marginTop:'20px'}}>Selecciona la <span style={{color:'#626262', fontSize:'15px', fontStyle:'italic', marginLeft:'5px', marginRight:'5px'}}> franja temporal </span> <span style={{color:'#8d8d8d', fontSize:'15px'}}> para tu evento</span></Row>
        <Row style={{marginTop:'60px', marginLeft:'10%', marginRight:'10%', justifyContent:'center'}}>
          <Col>

        <Row style={{justifyContent:'center'}} onClick={(event)=>{handleHora(event,'mañana')}}>
        {horario_elegido == "mañana" ?
                    <Image
              style={{height:'60px'}} 
              src={icon_mañana_sel} /> :
              <Image
              style={{height:'60px'}} 
              src={icon_mañana} />
              }
              </Row>
              <Row style={{justifyContent:'center',marginTop:'10px'}}>
                Mañana
              </Row>
        </Col>
        <Col>
        <Row style={{justifyContent:'center'}} onClick={(event)=>{handleHora(event,'tarde')}}>
        {horario_elegido == "tarde" ?
                    <Image
              style={{height:'60px'}} 
              src={icon_tarde_sel} /> :
              <Image
              style={{height:'60px'}} 
              src={icon_tarde} />
              }
              </Row>
              <Row style={{justifyContent:'center',marginTop:'10px'}}>Tarde</Row>
        </Col>
        <Col>
        <Row style={{justifyContent:'center'}} onClick={(event)=>{handleHora(event,'noche')}}>
        {horario_elegido == "noche" ?
                    <Image
              style={{height:'60px'}} 
              src={icon_noche_sel} /> :
              <Image
              style={{height:'60px'}} 
              src={icon_noche} />
              }
              </Row><Row style={{justifyContent:'center' ,marginTop:'10px'}}>
                Noche
              </Row>
        </Col>
          </Row>
        </>:
        <></>
        }


{ paso == 4 ?
        <>
        <Row style={{width:'899px'}}></Row>
        <Row style={{justifyContent:'center', color:'#8d8d8d', fontSize:'15px' , marginBottom:'20px'}}>Indicanos la <span style={{color:'#626262', fontSize:'15px', fontStyle:'italic', marginLeft:'5px', marginRight:'5px'}}> cantidad de invitados </span> <span style={{color:'#8d8d8d', fontSize:'15px'}}> que tendras</span></Row>
        

        <Form.Group controlId="formBasicAsistentes" style={{marginLeft:'35%', marginRight:'35%'}}>
            <Form.Control onChange={handleAsistentes} required type="number" placeholder="0" value={Asistentes}/>
          </Form.Group>
          
          <div style={{backgroundColor:'lightgray', height:'1px', width:'100%', marginBottom:'20px',marginTop:'10px'}}></div>

        <Row style={{justifyContent:'center', color:'#8d8d8d', fontSize:'15px' , marginTop:'20px'}}>Selecciona el <span style={{color:'#626262', fontSize:'15px', fontStyle:'italic', marginLeft:'5px', marginRight:'5px'}}> área dentro del club </span> <span style={{color:'#8d8d8d', fontSize:'15px'}}> para tu evento.</span></Row>
        <Row style={{marginLeft:'17%',marginRight:'17%',justifyContent:'center', color:'#8d8d8d', fontSize:'15px' , marginBottom:'20px', marginTop:'0px'}}>Te mostraremos activos los que estén disponibles en la hora y fecha que configuraste</Row>

          <Col style={{display:'flex', flexFlow:'wrap', marginTop:'60px', justifyContent:'center' }} >
          <div id="loaderPlaces" className="loader" style={{display:'none' ,marginTop:'30px' , color:'orange'}}></div>
          
          {Agenda.map(client => {
                return (
                  <>
                  
                  { (client.id).toString() == LocalizacionElegida ?
                    <Col className="" style={{minWidth:'280px', maxWidth:'280px', height:'250px', marginLeft:'5px', marginBottom:'5px', backgroundColor:'#a0a0a0'}} onClick={()=>{handleLocalizacionElegida(client)}}>
                      {client.imagen == 'chimenea' ?
                      <Image style={{minWidth:'250px', maxWidth:'250px', height:'200px'}} src={chimenea}></Image>
                      :<></>}
                      {client.imagen == 'reservado' ?
                      <Image style={{minWidth:'250px', maxWidth:'250px', height:'200px'}} src={reservado}></Image>
                      :<></>}
                      {client.imagen == 'salon' ?
                      <Image style={{minWidth:'250px', maxWidth:'250px', height:'200px'}} src={salon}></Image>
                      :<></>}
                      {client.imagen == 'barra' ?
                      <Image style={{minWidth:'250px', maxWidth:'250px', height:'200px'}} src={barra}></Image>
                      :<></>}
                      {client.imagen == 'salagrande' ?
                      <Image style={{minWidth:'250px', maxWidth:'250px', height:'200px'}} src={salagrande}></Image>
                      :<></>}
                      {client.imagen == 'jardin' ?
                      <Image style={{minWidth:'250px', maxWidth:'250px', height:'200px'}} src={jardin}></Image>
                      :<></>}
                      <span ><h6 style={{marginBottom:'-5px'}}>{client.nombre}</h6></span>
                      <span style={{fontSize:'14px', color:'lightgray'}}>{client.descripcion}</span>
                    </Col>:
                    <Col className="" style={{minWidth:'280px', maxWidth:'280px', height:'250px', marginLeft:'5px', marginBottom:'5px'}} onClick={()=>{handleLocalizacionElegida(client)}}>
                    {client.imagen == 'chimenea' ?
                    <Image style={{minWidth:'250px', maxWidth:'250px', height:'200px'}} src={chimenea}></Image>
                    :<></>}
                    {client.imagen == 'reservado' ?
                    <Image style={{minWidth:'250px', maxWidth:'250px', height:'200px'}} src={reservado}></Image>
                    :<></>}
                    {client.imagen == 'salon' ?
                    <Image style={{minWidth:'250px', maxWidth:'250px', height:'200px'}} src={salon}></Image>
                    :<></>}
                    {client.imagen == 'barra' ?
                    <Image style={{minWidth:'250px', maxWidth:'250px', height:'200px'}} src={barra}></Image>
                    :<></>}
                    {client.imagen == 'salagrande' ?
                    <Image style={{minWidth:'250px', maxWidth:'250px', height:'200px'}} src={salagrande}></Image>
                    :<></>}
                    {client.imagen == 'jardin' ?
                    <Image style={{minWidth:'250px', maxWidth:'250px', height:'200px'}} src={jardin}></Image>
                    :<></>}
                    <span ><h6 style={{marginBottom:'-5px'}}>{client.nombre}</h6></span>
                    <span style={{fontSize:'14px', color:'lightgray'}}>{client.descripcion}</span>
                  </Col>
                  }
</>
                );

              })}
          
          </Col>
      

        </>:
        <></>
        }

        
{ paso == 5 ?


<><><><div>
  <Row style={{ width: '899px' }}></Row>
  <Row className="mb-3" style={{ marginTop: '40px' }}><Col><h5>RESUMEN DE SU CONTRATACIÓN</h5>
    <Row style={{ backgroundColor: '#f3f4f4', borderColor: '#dfdfdf', borderWidth: '1px', borderStyle: 'solid', marginTop: '20px' }}></Row>
  </Col></Row>

  <Row className="justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>
    <Col>
      <Image style={{ height: '30px' }} src={uno} />
    </Col>
  </Row>

  <Row className="mb-3" style={{}}><Col><h5>PASES</h5></Col></Row>
  {subscriptions.map(client => {
    return (
      <Row className="mb-3" style={{}}>
        <Col className="">
          <span><h6>{client.nombre}</h6></span>
        </Col>
      </Row>
    );

  })}

  {Extras.length > 0 ?

    <><Row className="justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>
      <Col>
        <Image style={{ height: '30px' }} src={tres} />
      </Col>
    </Row><Row className="mb-3" style={{}}><Col><h5>SERVICIOS EXTRA</h5></Col></Row></> : <></>}
  {Extras.map(client => {
    return (
      <Row className="mb-3" style={{}}>
        <Col className="">
          <span><h6>{client.nombre}</h6></span>
        </Col>
      </Row>
    );

  })}<></>



  <Row className="justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>
    <Col>
      {Extras.length > 0 ?
        <Image style={{ height: '30px' }} src={tres} /> :
        <Image style={{ height: '30px' }} src={dos} />}
    </Col>
  </Row>
  <Row className="mb-3" style={{}}><Col><h5>POSTRES</h5></Col></Row>
  <Row className="mb-3" style={{}}>
    <Col className="">
      <span><h6>Degustación de postres de nuestra elaboración.</h6></span>
    </Col>
  </Row>


  <Row className="justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>
    <Col>
      {Extras.length > 0 ?
        <Image style={{ height: '30px' }} src={cuatro} /> :
        <Image style={{ height: '30px' }} src={tres} />}
    </Col>
  </Row>
  <Row className="mb-3" style={{}}><Col><h5>BEBIDA</h5></Col></Row>
  <Row className="mb-3" style={{}}>
    <Col className="">
      <span><h6>Cerveza de grifo, vino tinto y blanco, refrescos y agua.</h6></span>
    </Col>
  </Row>

  <Row style={{ backgroundColor: '#f3f4f4', borderColor: '#dfdfdf', borderWidth: '1px', borderStyle: 'solid', marginTop: '20px' }}></Row>

</div>


  <div style={{ backgroundColor: 'white', height: '20px', width: '100%', marginBottom: '60px' }}></div>
  <Row style={{ backgroundColor: '#fafafa', borderColor: '#dfdfdf', borderWidth: '1px', borderStyle: 'solid' }}>
    <Col>

      <Row style={{ justifyContent: 'center', marginTop: '30px', fontSize: 'medium' }}>
        <Col style={{ textAlign: 'center' ,color:'black' }}>Número de invitados
        <span style={{ marginLeft:'5px', textAlign: 'initial' ,color:'gray'}}>{Asistentes} Personas</span></Col>
      </Row>
      <Row style={{ justifyContent: 'init', marginTop: '-5px', marginBottom: '0px', fontSize: 'medium' }}>
        <Col style={{ textAlign: 'center' ,color:'black'}}>Localización
        <span style={{ marginLeft:'5px', textAlign: 'initial' ,color:'gray' }}>{localizacion_elegida}</span></Col>
      </Row>
      <Row style={{ justifyContent: 'init', marginTop: '-5px', marginBottom: '0px', fontSize: 'medium' }}>
        <Col style={{ textAlign: 'center' ,color:'black'}}>Fecha
        <span style={{ marginLeft:'5px', textAlign: 'initial' ,color:'gray' }}>{fecha_consulta}</span></Col>
      </Row>
      <Row style={{ justifyContent: 'init', marginTop: '-5px', marginBottom: '30px', fontSize: 'medium' }}>
        <Col style={{ textAlign: 'center' ,color:'black'}}>Horario
        <span style={{ marginLeft:'5px', textAlign: 'initial' ,color:'gray' }}>Por la {horario_elegido}</span></Col>
      </Row>
    </Col>
  </Row>

  <Row style={{ backgroundColor: '#f3f4f4', borderColor: '#dfdfdf', borderWidth: '1px', borderStyle: 'solid' }}>
    <Col>

      <Row style={{ justifyContent: 'init', marginTop: '30px', fontSize: 'medium' }}>
        <Col style={{ textAlign: 'initial' }}>Precio del menú</Col>
        <Col style={{ textAlign: 'end' }}>EUR {totalMenu}.00</Col>
      </Row>
      <Row style={{ justifyContent: 'init', marginTop: '-5px', marginBottom: '30px', fontSize: 'medium' }}>
        <Col style={{ textAlign: 'initial' }}>Precio de los servicios extra.</Col>
        <Col style={{ textAlign: 'end' }}>EUR {totalExtras}.00</Col>
      </Row>
      <Row style={{ justifyContent: 'init', marginTop: '-5px', marginBottom: '30px', fontSize: 'medium' }}>
        <Col style={{ textAlign: 'initial' }}>COSTO TOTAL</Col>
        <Col style={{ textAlign: 'end' }}>EUR {totalExtras+(totalMenu*Asistentes)}.00</Col>
      </Row>
      <Row style={{ justifyContent: 'init', marginTop: '-35px', marginBottom: '30px', fontSize: 'small' }}>
      <Col style={{ textAlign: 'initial' ,color:'#bf822b'}}>({Asistentes} Invitados)</Col>
        </Row>
    </Col>
  </Row>

</>
<Row style={{marginTop:'80px', justifyContent:'center'}}>
<Image style={{ height: '100px' }} src={pagoAnticipo} />
</Row>
<Row style={{marginTop:'20px', justifyContent:'center'}}>
<h6>Para reservar, realiza un anticipo de EUR 300.00*</h6>
</Row>
<Row style={{marginTop:'-5px', justifyContent:'center'}}>
<p style={{color:'gray', fontSize: 'small'}}>*EUR 9.00 de comisiones del cobro por tarjeta</p>
</Row>
</><>
<Row className="mb-3" style={{ marginTop: '40px' }}><Col><h5>REALIZAR PAGO DE RESERVA</h5>
    <Row style={{ backgroundColor: '#f3f4f4', borderColor: '#dfdfdf', borderWidth: '1px', borderStyle: 'solid', marginTop: '20px' }}></Row>
  </Col></Row>

    <Form.Row className="mb-3">
      <Col>
        <Row style={{borderStyle:'solid', borderWidth:'1px', borderRadius:'5px', width:'100%', marginLeft:'0px', borderColor:'#ced4da'}}>
          <Col style={{maxWidth:'25px', display:'contents'}}><Image src={iconoNombre} style={{height:'23px', width:'20px', marginTop:'6px', marginLeft:'10px'}} /></Col>
          <Col style={{marginLeft:'-15px'}}><Form.Control style={{borderStyle:'hidden', boxShadow:'none'}} onChange={changeCardName} required type="email" placeholder="Cardholder full name" /></Col>
        </Row>  
      </Col>
    </Form.Row>
    <Form.Group>
      <Form.Row className="info-credit-card">
      <Col>
        <Row style={{borderStyle:'solid', borderWidth:'1px', borderRadius:'5px', width:'100%', marginLeft:'0px', borderColor:'#ced4da'}}>
        <Col style={{maxWidth:'25px', display:'contents'}}><Image src={iconoTarjeta} style={{height:'17px', width:'20px', marginTop:'10px', marginLeft:'10px'}} /></Col>
          <Col style={{marginLeft:'-15px'}}><Form.Control style={{borderStyle:'hidden', boxShadow:'none'}} maxLength={19} onChange={changeCardNumber} required type="email" placeholder="Card Number" /></Col>
        </Row>  
      </Col>
      </Form.Row>
    </Form.Group>
    <Form.Group>
      <Form.Row className="info-credit-card">

        <Col md="2">
          <Form.Control required type="text" style={{boxShadow:'none'}} minLength={2} maxLength={2} placeholder="MM" onChange={changeMonth} />
        </Col>
        <Col md="2">
          <Form.Control
            required
            ref={yearInputRef}
            style={{boxShadow:'none'}}
            type="text"
            minLength={4}
            maxLength={4}
            placeholder="YYYY"
            onChange={changeYear} />
        </Col>
        <Col md="2">
          <Form.Control
            required
            ref={cvvInputRef}
            type="text"
            minLength={3}
            maxLength={4}
            placeholder="CVV"
            style={{boxShadow:'none'}}
            onChange={changeCVV} />
        </Col>
      </Form.Row>
    </Form.Group>
    {alerta && (
      <Alert variant='success'>
        Por favor, permite abrir la ventana flotante para poder confirmar el pago con tu entidad financiera
      </Alert>)}
    {showError && (
      <Alert variant='danger'>
        {errorMessage}
      </Alert>)}

  </></>:
<></>


}
{ paso == 6 ?
        <>
          <Row style={{ width: '899px' }}></Row>
          <Image style={{minWidth:'250px', maxWidth:'250px'}} src={exitopago}></Image>
          <Row style={{ backgroundColor: '#f3f4f4', borderColor: '#dfdfdf', borderWidth: '1px', borderStyle: 'solid', marginTop: '20px' }}></Row>
          <Row className="mb-3" style={{ marginTop: '40px' }}><Col><h5>¡PREPARATE PARA TU EVENTO!</h5></Col></Row>
          <Row className="mb-3" style={{ marginTop: '0px' }}><Col style={{ marginTop: '-25px', fontSize:'17px' }}>Tu pago fue procesado con éxito.</Col></Row>
        </>:<></>
}


<Row className="justify-content-center" >
      <div className="rounded px-5 pb-4" style={{marginTop:'60px', width:'100%'}} > 
      <Row style={{justifyContent:'center'}}>
        
        
        
      {paso==2 ?
          <>
          
                  <Col>
                  <Row style={{  }}>
                    <Button style={{marginRight:'5p',backgroundColor:'gray', borderColor:'gray', height:'38px'}} className="my-2 btn-block" onClick={(e) => {handleRetrocedePaso() } }>
                      Regresar a configurar
                      {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                    </Button>
                  </Row>
                  </Col>
                  <Col>
                      <Row style={{  }}>
                       
                          <Button style={{marginLeft:'5px'}} className="my-2 login btn-block" onClick={() => { avanzarMenu(); } }>
                            Continuar
                            {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                          </Button>
                      </Row>
                    </Col></>
          :


          <>
                  
      </>
          
          }

{paso>0 && paso!=2 && paso<6 ?

                  <><Col>
                    <Row style={{ justifyContent: 'end' }}>
                      <Button style={{ marginRight:'5px', backgroundColor: 'gray', width: '150px', borderColor: 'gray', height: '38px' }} className="my-2 btn-block" onClick={(e) => { handleRetrocedePaso(); } }>
                        Regresar
                        {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                      </Button>
                    </Row>
                  </Col><Col>
                      <Row style={{ width: '150px' }}>
                        {canContinue ?
                          <Button style={{marginLeft:'5px'}} className="my-2 login btn-block" onClick={() => { avanzarMenu(); } }>
                            Continuar
                            {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                          </Button>
                          :
                          <Button type="submit" className="my-2 loginDeactive btn-block" style={{ cursor: 'not-allowed', marginLeft:'5px' }}>
                            Continuar
                            {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                          </Button>}
                      </Row>
                    </Col></>

:
<></>

}

{paso == 0 || paso == 6 ? 

<>
<><div className="rounded px-5 pb-4" style={{ width: '890px' }}> </div></>
<Row style={{ width: '150px' }}>
    {canContinue ?
      <Button className="my-2 login btn-block" onClick={() => {  handleAvanzaPaso(); } }>
        Continuar
        {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
      </Button>
      :
      <Button type="submit" className="my-2 loginDeactive btn-block" style={{ cursor: 'not-allowed' }}>
        Continuar
        {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
      </Button>}
  </Row>
  </>
    :
    <></>

    }
  </Row>
  </div>
  </Row>
  </div>
</Row>

      <Row className="mt-auto">
        <Col className="px-0">
          {/*<Footer/>*/}
        </Col>
      </Row>
    </Container>
  );
}


export default MainPageCocktail;

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function isNumber(myString) {
  return /^\d+$/.test(myString);
}