// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Intro/Intro Bold Regular.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/Intro/Intro Bold Alt Regular.otf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/Intro/Intro Book Alt Regular.otf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/Raleway/Raleway-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/Brandon Grothesque/brandon-grotesque-light-58a8a4b38001d.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "/* ----- Font face ----- */\n@font-face {\n  font-family: 'Intro';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n  font-family: 'Regular Intro Bold Alt';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n\n@font-face {\n  font-family: 'Regular Intro Book Alt';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  font-weight: bold;\n}\n\n@font-face {\n  font-family: 'Raleway';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n\n@font-face {\n  font-family: 'Brandon Grothesque Light';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
