import {  RouteProps } from 'react-router-dom';
import React from 'react';
import MainPage from '../components/presupuestos/mainPage';
import MainPageCocktail from '../components/presupuestos/mainPageCocktail';
import MainPageEventosLaGloria from '../components/presupuestos/mainPageEventoLaGloria';
import MainPageEventos from '../components/presupuestos/mainPageEvento';
//import '../assets/css/baseLayoutCaprice.css';
import '../assets/css/card.css';
import '../assets/css/contentdoc.css';
import '../assets/css/cookieManage.css';
import '../assets/css/dashboard';
import '../assets/css/font-face.css';
import '../assets/css/prism.css';
import '../assets/css/signup.css';
import '../assets/css/style.css';
import '../assets/css/styleDash.css';
import '../assets/css/Calendar.css';
import '../assets/css/verificationNotice.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainPageEventosMenu from '../components/presupuestos/mainPageEventoMenu';

/**
 * Este bloque crea un componente que usaremos para rutas privadas
 * es decir, todas aquellas que se necesite estar logueado previamente
 * el funcionamiente lo que hace es comprobar si está loguead manda a la ruta, 
 * en caso de que no redirecciona al login.
 */

// Rutas a las que todo el mundo tiene acceso
export const publicRoutes:Array<RouteProps> = [
  {
    path: '/',
    component: (state, props) => {
      return (<MainPageEventosLaGloria 
     //   nameClient={state.location.state.extraProjectsPlan} 
     //   mailClient={state.location.state.extraRequestsPlan} 
     //   phoneClient={state.location.state.planID}
     //   sesion={state.location.state.sesion}
      />)
    },
    exact: true
  },
  {
    path: '/coctel',
    component: (state, props) => {
      return (<MainPageCocktail
        nameClient={state.location.state.extraProjectsPlan} 
        mailClient={state.location.state.extraRequestsPlan} 
        phoneClient={state.location.state.planID}
        sesion={state.location.state.sesion}
      />)
    },
    exact: true
  },
  {
    path: '/eventos',
    component: (state, props) => {
      return (<MainPageEventos 
     //   nameClient={state.location.state.extraProjectsPlan} 
     //   mailClient={state.location.state.extraRequestsPlan} 
     //   phoneClient={state.location.state.planID}
     //   sesion={state.location.state.sesion}
      />)
    },
    exact: true
  },
  {
    path: '/laglolria',
    component: (state, props) => {
      return (<MainPageEventosLaGloria 
     //   nameClient={state.location.state.extraProjectsPlan} 
     //   mailClient={state.location.state.extraRequestsPlan} 
     //   phoneClient={state.location.state.planID}
     //   sesion={state.location.state.sesion}
      />)
    },
    exact: true
  },
  {
    path: '/villacaprice',
    component: (state, props) => {
      return (<MainPageEventos 
     //   nameClient={state.location.state.extraProjectsPlan} 
     //   mailClient={state.location.state.extraRequestsPlan} 
     //   phoneClient={state.location.state.planID}
     //   sesion={state.location.state.sesion}
      />)
    },
    exact: true
  },
  {
    path: '/villa_caprice',
    component: (state, props) => {
      return (<MainPageEventosMenu 
     //   nameClient={state.location.state.extraProjectsPlan} 
     //   mailClient={state.location.state.extraRequestsPlan} 
     //   phoneClient={state.location.state.planID}
     //   sesion={state.location.state.sesion}
      />)
    },
    exact: true
  },

  

]

