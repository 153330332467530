import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { Row, Col, Container, Image, Form, Button, Spinner, Alert } from 'react-bootstrap';
import Calendar from 'react-calendar';
import Logo2 from '../../assets/img/bannerlagloria.png';
import '../../assets/plansPricings.css';
import '../../assets/css/baseLayout.css';
import exitopago from '../../assets/img/exitopago.png';
import pasos1 from '../../assets/img/pasos_uno.png';
import pasos2 from '../../assets/img/pasos_dos.png';
import pasos3 from '../../assets/img/pasos_tres.png';
import pasos4 from '../../assets/img/pasos_cuatro.png';
import pasos5 from '../../assets/img/pasos_cinco.png';
import pasos6 from '../../assets/img/pasos_seis.png';
import comida from '../../assets/img/ilustracioncomida.png';
import uno from '../../assets/img/01_formulario.png';
import dos from '../../assets/img/02_formulario.png';
import tres from '../../assets/img/03_formulario.png';
import cuatro from '../../assets/img/04_formulario.png';
import cinco from '../../assets/img/05_formulario.png';
import seis from '../../assets/img/06_formulario.png';
import siete from '../../assets/img/07_formulario.png';
import ocho from '../../assets/img/08_formulario.png';
import nueve from '../../assets/img/09_formulario.png';
import diez from '../../assets/img/10_formulario.png';

import once from '../../assets/img/11_formulario.png';
import doce from '../../assets/img/12_formulario.png';
import trece from '../../assets/img/13_formulario.png';
import catorce from '../../assets/img/14_formulario.png';
import quince from '../../assets/img/15_formulario.png';
import dieciseis from '../../assets/img/16_formulario.png';


import iconoNombre from '../../assets/img/icononombre.png';
import iconoCorreo from '../../assets/img/iconocorreo.png';
import iconoTelefono from '../../assets/img/iconotelefono.png';
import iconoTarjeta from '../../assets/img/iconotarjeta.png';
import pagoAnticipo from '../../assets/img/pagoanticipo.png';
import icon_mañana from '../../assets/img/mañana.png';
import icon_tarde from '../../assets/img/tarde.png';
import icon_noche from '../../assets/img/noche.png';
import icon_mañana_sel from '../../assets/img/mañana_sel.png';
import icon_tarde_sel from '../../assets/img/tarde_sel.png';
import icon_noche_sel from '../../assets/img/noche_sel.png';
import chimenea from '../../assets/img/chimenea.png';
import reservado from '../../assets/img/reservado.png';
import salon from '../../assets/img/salon.png';
import barra from '../../assets/img/barra.png';
import jardin from '../../assets/img/jardin.png';
import salagrande from '../../assets/img/salagrande.png';
import private_key from '../../configs/keys'
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getToday } from '../../services/TimeService';
import { useHistory } from 'react-router-dom';
import { Console } from 'console';

type MainPageEventos = {
  userLabel?: string,
  passLabel?: string,
  rememberCheckboxLabel?: string,
  submitButtonLabel?: string,
  missingUserMsg?: string,
  missingPassMsg?: string,
  loginFailedMsg?: string
}

type MainProps = {
  //nameClient: string,
  //mailClient:string,
  //phoneClient:string,
  //sesion:string
}

const MainPageEventosLaGloria:FunctionComponent<MainProps> = props => {

  const { 
    //nameClient,
    //mailClient,
    //phoneClient,
    //sesion
  } = props;

  const history = useHistory();

  const [Session, setSession] = useState('');
  const [Cargando, setCargando] = useState(false);
  const [Pagando, setPagando] = useState(false);
  const crypto = require('crypto');
  const [Agenda, setAgenda] = useState([]);
  const [valueCalendar, onChangeCalendar] = useState(new Date());
  const [errorMessage, SeterrorMessage] = useState("Transaccion Fallida, intentelo de nuevo mas tarde.");
  const [showError, setShowError] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [cvv, setCVV] = useState('');
  const [PlaceholEntrantes, setPlaceholEntrantes] = useState([]);
  const [PlaceholPrincipales, setPlaceholPrincipales] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Name, setName] = useState('');
  const [Asistentes, setAsistentes] = useState(0);
  const [Email, setEmail] = useState('');
  const [Phone, setPhone] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [alerta, setAlerta] = useState(false);
  const [LocalizacionElegida, setLocalizacionElegida] = useState(0)
  const [horario_elegido, set_horario_elegido] = useState('');
  const [localizacion_elegida, setlocalizacion_elegida] = useState('')
  const [fecha_consulta, setFechaConsulta] = useState(getToday());
  const today =  getToday();
  const [EntranteToAdd, setEntranteToAdd] = useState('');
  const [PriceEntranteToAdd, setPriceEntranteToAdd] = useState(0.0);
  const [PrincipalToAdd, setPrincipalToAdd] = useState('');

  const [PrimerOpcion, setPrimerOpcion] = useState([]);
  const [OpcionToAdd, setOpcionToAdd] = useState([]);
  const [PriceOpcionToAdd ,setPriceOpcionToAdd] = useState([]);
  const [AsteriscoToAdd ,setAsteriscoToAdd] = useState([]);

  const [PricePrincipalToAdd, setPricePrincipalToAdd] = useState(0.0);
  const [ExtrasToAdd, setExtrasToAdd] = useState('');
  const [PriceExtrasToAdd, setPriceExtrasToAdd] = useState(0.0);
  const [paso, setPaso] = useState(0);
  const [canContinue, setContinue] = useState(false);
  const [ListSubscription, setsubscriptionL] = useState([]);
  const [ListSubscriptionFull, setsubscriptionLF] = useState([]);
  const [ListSubscription2, setsubscriptionL2] = useState([]);
  const [ListSubscription3, setsubscriptionL3] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [PlatosPrincipals, setPlatosPrincipales] = useState([]);
  const [ListLocalizaziones, setListLocalizaziones] = useState([]);
  const [FranjasHorarias, setFranjasHorarias] = useState([])
  const [localityP, setLocalityP] = useState('');
  const [primerEntrante, setPrimerentrante] = useState('');
  const [principal, setPrincipal] = useState('');
  const [Extras, setListadoExtras] = useState([]);
  const [extra, setExtra] = useState('');
  const [totalMenu, setTotalMenu] = useState(0.0);
  const [totalExtras, setTotalExtras] = useState(0.0);
  const [OriglistaEntrantes ,setOriglistaEntrantes] = useState([]);
  const [OriglistaPrincipales ,setOriglistaPrincipales] = useState([]);
  const [OriglistaExtras, setOriglistaExtras] = useState([]);
  const [OriglistaOpciones, setOriglistaOpciones] = useState([]);
  const yearInputRef = useRef(null);
  const cvvInputRef = useRef(null);
  const [desplegables, setDesplegables] = useState(0);
  const [longDesplegables, setlongDesplegables] = useState([]);
  const [numerales, setNumerales] = useState(["alguna","una","dos","tres","cuatro","cinco","seis","siete","ocho","nueve","diez","once","doce","trece","catorce","quice","dieciseis","diecisiete","dieciocho","diecinueve","veinte"]);
  const [CategoriasPrincipales, setCategoriasPrincipales] = useState([]);
  const [opcionesSeleccionadas, setOpcionesSeleccionadas] = useState([]);
  const [Secundarias, setSecundarias] = useState(false);
  const [arbolMenu, setarbolMenu] = useState([]);
  const [TituloMenu, setTituloMenu] = useState('');
  const [PrecioBaseMenu, setPrecioBaseMenu] = useState(0.0);
  const [TotalSecs, setTotalSecs] = useState(0);
  const [loaderOpciones, setLoaderOpciones] = useState(false);
  const [loaderMenu, setloaderMenu] = useState(false);
  const [categoriaEvento, setCategoriaEvento] = useState('');

  //setSituloMenu(categoria.titulo);
  //setPrecioBaseMenu(categoria.precio_base);
  const [NextSubCategories,setNextSubCategories] = useState([]);


  let updates = React.useRef(0);
  const handleAvanzaPaso = (categoria=null) =>{
    //console.log(canContinue);
    if (canContinue){

      var categoRegistro;

      if (categoria==null) categoRegistro=categoriaEvento; else categoRegistro=categoria;

      if (paso==0) registerStep(true,'not_definited'); else registerStep(false,categoRegistro);
      
      if (paso+1!=3) setContinue(false); else setContinue(true);

      if (paso+1==2 && subscriptions.length>2 && PlatosPrincipals.length>0) setContinue(true);

      if (paso+1==4 && horario_elegido!='') setContinue(true);

      if (paso+1==5 && Asistentes>0 && LocalizacionElegida>0) setContinue(true);

      if (paso+1==3) {
        var total_secs=0
        longDesplegables.map(
          seccion=>{
            if (opcionesSeleccionadas[seccion.id].length>0) total_secs++;
          }
          
        )
        setTotalSecs(total_secs);
        setContinue(true);
      
      }

      if (paso+1==8) {
        history.push({
          pathname: "/"
        });
      }

      if (paso+1==2){

        //getSubscriptions('entrantes');
        getConfiguracion(categoria);
        
        //getPrimerPlato();
        //getExtras();
      }

      if (paso+1 == 1) {
        
        //console.log(CategoriasPrincipales);
        setContinue(true);
      }

      setPaso(paso+1);
      


    }
    
    
  }

  const handleRetrocedePaso = () =>{
      var pasoAnterior;
      if (!Pagando){
      pasoAnterior = paso-1;
      if (pasoAnterior == 0){

        setPhone(Phone);
        setEmail(Email);
        setName(Name)

        if (Phone.length>8 && Name!="" && Email.includes('@') && Email.includes('.')){
          setContinue(true); 
        } else setContinue(false);
      }

      if (pasoAnterior ==1 ) setContinue(true);
      if (pasoAnterior == 2){
  
        var avanzar = true;

        //console.log(longDesplegables);
        //console.log(opcionesSeleccionadas);      
    
        longDesplegables.map(
          seccion=>{
            if (seccion.cantidad>opcionesSeleccionadas[seccion.id].length) avanzar=false;
          }
          
        )
    
        setContinue(avanzar);
        
      } 

      if (pasoAnterior == 4){
        if (horario_elegido!='' && fecha_consulta!='') setContinue(true);
      }

      if (pasoAnterior == 5){
        if (Asistentes>0 && LocalizacionElegida>0){
          setContinue(true); 
        } else setContinue(false);
  
      }
      if (paso==1){
        var arbolmenutmp = arbolMenu;
        if (arbolMenu.length<2) setSecundarias(false);
        if (arbolMenu.length<1) setPaso(pasoAnterior);
        if (arbolMenu.length>0){

          console.log(arbolMenu[arbolMenu.length-1]);
          arbolmenutmp = arbolmenutmp.filter(item => item !== arbolMenu[arbolMenu.length-1])
          setarbolMenu(arbolmenutmp);
          
          console.log(arbolMenu);
          console.log(arbolmenutmp);
          if (arbolmenutmp.length>0){
            getSubCategories(arbolmenutmp[arbolmenutmp.length-1]);
          }
        }
        

      } else {
        setSecundarias(false);
        setPaso(pasoAnterior)
      }
      
    }
    }


  const changeCardName = (e) => {
    setCardName(e.target.value);
    if (e.target.value!="" && cardNumber.length>15 && month.length>0 && year.length==4 && cvv.length>2) setContinue(true); else setContinue(false);
  }
  
  const changeCardNumber = (e) => {
    var newCardNumber = e.target.value.split(" ").join(""); // remove hyphens
    if (cardName!="" && newCardNumber.length==16 && month.length>0 && year.length==4 && cvv.length>2) setContinue(true); else setContinue(false);

    if (isNumber(newCardNumber)) setCardNumber(newCardNumber); else newCardNumber=cardNumber.split(" ").join("");
    if (newCardNumber.length > 0) {
      newCardNumber = newCardNumber.match(new RegExp('.{1,4}', 'g')).join(" ");
    }
    e.target.value = newCardNumber;
  
  }
  
  const changeMonth = (e) => {
    var newMonth = e.target.value;
    if (isNumber(newMonth)) setMonth(newMonth); else {newMonth=month;e.target.value=month;}
    if (newMonth.toString().length == e.target.maxLength){
      yearInputRef.current.focus();
    }
    if (cardName!="" && cardNumber.length==16 && newMonth.length>0 && year.length==4 && cvv.length>2) setContinue(true); else setContinue(false);
  }
  
  const changeYear = (e) => {
    var newYear = e.target.value;
    if (isNumber(newYear)) setYear(newYear); else {newYear=year;e.target.value=year;}
    if (newYear.toString().length == e.target.maxLength){
      cvvInputRef.current.focus();
    }
    if (cardName!="" && cardNumber.length==16 && month.length>0 && newYear.length==4 && cvv.length>2) setContinue(true); else setContinue(false);
  }
  
  const changeCVV = (e) => {
    var newCVV = e.target.value;
    if (isNumber(newCVV)) setCVV(newCVV); else {newCVV=cvv;e.target.value=cvv;}
    if (cardName!="" && cardNumber.length==16 && month.length>0 && year.length==4 && newCVV.length>2) setContinue(true); else setContinue(false);
  }

  const handleFecha = (e, fecha) => {
    setFechaConsulta(fecha); 
}   

  const handleHora = (e, horario) => {
    set_horario_elegido(horario); 
    setContinue(true);
  }
  
  const muestraContrato = async (payment_id) =>{
    document.getElementById("slp").style.display = "none";
    var documento_a_firmar=
        '<html width="1735" height="1227">' + 
        '<style> html, body { width: 1735px; height: 1227px; background-repeat: no-repeat;}</style>' +
        '<body background="/plantillaQRV3.png" >' +
        '<p>PASES</p>'
        subscriptions.map(cus => {
          documento_a_firmar+= '<p>'+cus.nombre+'<p>';
  
        });
        documento_a_firmar+=
        '<p>PLATO PRINCIPAL</p>'+
        PlatosPrincipals.map(cus => {
          documento_a_firmar+='<p>'+cus.nombre+'<p>';
    
        });
    documento_a_firmar+=
    '<p>EXTRAS</p>'+
    Extras.map(cus => {
      documento_a_firmar+='<p>'+cus.nombre+'<p>';

    });

    var d = new Date();
    var n = d.toLocaleTimeString();


    documento_a_firmar+=
    '<p>CARACTERISTICAS DEL EVENTO</p>'+
    '<p><col>Fecha del coctel <col><col>'+fecha_consulta+'</col></p>'+
    '<p><col>Horario del coctel <col><col>'+horario_elegido+'</col></p>'+
    '<p><col>Lugar del coctel <col><col>'+localizacion_elegida+'</col></p>'+
    '<p><col>Asistentes al coctel <col><col>'+Asistentes+'</col></p>'+
    '<p></p>'+
    '<p>PAGO DE ANTICIPO</p>'+
    '<p><col>Anticipo abonado <col><col>EUR 309.00</col></p>'+
    '<p><col>Identificador del pago <col><col>'+payment_id+'</col></p>'+
    '<p><col>Fecha del abono <col><col>'+getToday()+'</col></p>'+
    '<p><col>Hora del abono <col><col>'+n+'</col></p>'+
    '<p></p>'

    
    const signer = crypto.createSign('RSA-SHA256');
    signer.write(documento_a_firmar);
    signer.end();
    const firma = signer.sign(private_key, 'base64')

    registraEvento(payment_id,firma);

    var a = window.open('', '', 'width=1735, height=1227');
    a.document.write('<html width="1735" height="1227">');
    a.document.write('<style> html, body { width: 1735px; height: 1227px; background-repeat: no-repeat;}</style>')
    a.document.write('<body background="/plantillaQRV3.png" >');
    a.document.write('<p>ENTRANTES</p>');
    subscriptions.map(cus => {
      a.document.write('<p>'+cus.nombre+'<p>');
      });

    a.document.write('<p>PLATO PRINCIPAL</p>');
    PlatosPrincipals.map(cus => {
      a.document.write('<p>'+cus.nombre+'<p>');
      });


    a.document.write('<p>EXTRAS</p>');
    Extras.map(cus => {
      a.document.write('<p>'+cus.nombre+'<p>');
      });
    a.document.write('<p>CARACTERISTICAS DEL EVENTO</p>');
    a.document.write('<p><col>Fecha del coctel <col><col>'+fecha_consulta+'</col></p>');
    a.document.write('<p><col>Horario del coctel <col><col>'+horario_elegido+'</col></p>');
    a.document.write('<p><col>Lugar del coctel <col><col>'+localizacion_elegida+'</col></p>');
    a.document.write('<p><col>Asistentes al coctel <col><col>'+Asistentes+'</col></p>');
    a.document.write('<p></p>');
    a.document.write('<p>PAGO DE ANTICIPO</p>');
    a.document.write('<p><col>Anticipo abonado <col><col>EUR 309.00</col></p>');
    a.document.write('<p><col>Identificador del pago <col><col>'+payment_id+'</col></p>');
    a.document.write('<p><col>Fecha del abono <col><col>'+getToday()+'</col></p>');
    a.document.write('<p><col>Hora del abono <col><col>'+n+'</col></p>');
    a.document.write('<p></p>');
    a.document.write('<p>FIRMA</p>');
    a.document.write('<p>'+firma+'</p>');
    a.document.write('</body></html>');
    a.document.close();
    //
    setTimeout(function() { 
      a.print();
      }.bind(this), 2000)
  }

  const registraEvento = async (payment_id, firma) => {

    var Nombre = '';
    var Correo = '';
    var Telef = ''
    if (Name=="") Nombre = Name; else Nombre = Name;
    if (Email=="") Correo = Email; else Correo = Email;
    if (Phone=="") Telef = Phone; else Telef = Phone;


    var listaEntrantesMarcados = "{'entrantes':[";

    subscriptions.map(client => {listaEntrantesMarcados+="'"+client.nombre+"',"});

    listaEntrantesMarcados+="]}";

    var listaPrincipalMarcados = "{'principales':[";

    PlatosPrincipals.map(client => {listaPrincipalMarcados+="'"+client.nombre+"',"});

    listaPrincipalMarcados+="]}";

    var listaExtrasMarcados = "{'extras':[";

    Extras.map(client => {listaExtrasMarcados+="'"+client.nombre+"',"});

    listaExtrasMarcados+="]}";



    var opciones = "{}"
    var listaopcionesmarcadas = "{'opciones':[";
    //console.log("++++++++++++++++")

    longDesplegables.map(
      seccion=>{

        listaopcionesmarcadas+="{'"+seccion.tipo+"':[";
        opcionesSeleccionadas[seccion.id].map(
          opcion=>{
            console.log(opcion);
            listaopcionesmarcadas+="'"+opcion.nombre+"',"
          }

        )
        listaopcionesmarcadas+="]},";
      }
    )
    listaopcionesmarcadas+="]}";
    //console.log("---------------")
    //console.log(listaopcionesmarcadas);
    //console.log("===============")



    const result = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/register_event_agenda?date=${fecha_consulta}&hour=${horario_elegido}&seated=${Asistentes}&standed=0&localization=${LocalizacionElegida}&pay_id=${payment_id}&nombre=${Nombre}&email=${Correo}&telefono=${Telef}&sesion=${Session}&tipo_evento=${categoriaEvento}&firma=${firma}&entrantes=${listaEntrantesMarcados}&primeros=${listaPrincipalMarcados}&pases={}&extras=${listaExtrasMarcados}&opciones=${listaopcionesmarcadas}`);

  }

  const payAnticipo = async () => {

    var Nombre = '';
    var Correo = '';
    var Telef = ''
    if (Name=="") Nombre = Name; else Nombre = Name;
    if (Email=="") Correo = Email; else Correo = Email;
    if (Phone=="") Telef = Phone; else Telef = Phone;

    setContinue(false);
    setShowError(false);
    setPagando(true);
    
    const result = await ObexProjectsRequestHandler.get(`/ws/stripe_interface.py/create_payment_method?id_canal=1&codigo_pais=ES&username=${Nombre}&email=${Correo}&card_number=${cardNumber}&card_name=${cardName}&month_exp=${month}&year_exp=${year}&cvv=${cvv}&amount=300&license=anticipo`);

    try{
      if ('next_action' in result){

        setAlerta(true);
        open(result['next_action']["use_stripe_sdk"]["stripe_js"]);

        var completed = false;
        const payload2 = {
          payment_id: result["payment_id"].toString(),
        }

        var codigo_llamada = 500;
  
        while (!completed){
          await sleep(5000);

          try{
          const resulta = await ObexProjectsRequestHandler.get(`/ws/stripe_interface.py/check_card_payment?id_canal=1&codigo_pais=ES&payment_id=${result["payment_id"]}`);
  
          if ('status' in resulta){
            if ('completed' in resulta){
              if (resulta['completed']) {
                completed = true;
                if (resulta["total_captured"]==30000) {

                setPaso(paso+1);
                document.getElementById("slp").style.display = "none";
                muestraContrato(result['payment_id']);

                } else {
                  setShowError(true);
                  SeterrorMessage("El pago no ha podido ser realizado, consulte con su entidad financiera.");
                  setPagando(false);
                  setCargando(false);
                  document.getElementById("slp").style.display = "none";
                }
                setContinue(true)
              }
              
            } else {
              // Pago no realizado
              setShowError(true);
              SeterrorMessage("El pago no ha podido ser realizado, consulte con su entidad financiera.");
              setCargando(false);
              document.getElementById("slp").style.display = "none";
              setPagando(false);
            }
          } else {
          // PAGO NO REALIZADO
          setShowError(true);
          SeterrorMessage("El pago no ha podido ser realizado, consulte con su entidad financiera.");
          setPagando(false);
          setCargando(false);
          document.getElementById("slp").style.display = "none";
          }
          } catch{
          }
        }      
      } else {
        if ('payment_id' in result){
          setPaso(paso+1);
          setContinue(true)
          document.getElementById("slp").style.display = "none";
          muestraContrato(result['payment_id']);
        } else {
          if (result["message"].toString().includes('rolled back') || result["message"].toString().includes("AdminShutdown")) {
            setContinue(false)
            payAnticipo();
          } else {
            setContinue(true)
            setShowError(true);
            SeterrorMessage("El pago no ha podido ser realizado, consulte con su entidad financiera.");
            setPagando(false);
            setCargando(false);
            document.getElementById("slp").style.display = "none";
          }
        }
      }
    } catch {      
        const { success, data, message } = result;
        if (success){
          if (result.toString().includes('created')){
            //console.log("PAGO REALIZADO");
            setSuccess("Pago realizado con éxito");
            setLoading(false);
            setCargando(false);
            document.getElementById("slp").style.display = "none";
            muestraContrato(result["payment_id"]);
            //routeLogin();
          }
      }else {
        setError(message);
        setLoading(false);
        setPagando(false);
        setCargando(false);
        document.getElementById("slp").style.display = "none";
      } 
    }  

  }


  const avanzarMenu = async () => {
    if (paso==2){
      var totalentrantes = 0.0;
      subscriptions.map(cus => {
        totalentrantes+=cus.precio;

      });
      totalentrantes-=15;

      var totaprincipal = 0.0;
      PlatosPrincipals.map(cus => {
        totaprincipal+=cus.precio;

      });
      totaprincipal-=15;

      var totaextras = 0.0;
      Extras.map(cus => {
        totaextras+=cus.precio;

      });
      var extrasvalor = 0;
      var precio_base = 0;
      var asterisco = 0;
      var parcial = 0;
      longDesplegables.map(seccion=>{
        console.log(seccion);
        if (seccion.tipo!="extras"){
          precio_base=seccion.precio_base;
          asterisco=seccion.asterisco;

          opcionesSeleccionadas[seccion.id].map(
            opcion=>{
              console.log(opcion)
              parcial+=precio_base;
              parcial+=(seccion.asterisco*opcion.asteriscos)
            }

          )

        } else {
          opcionesSeleccionadas[seccion.id].map(
            opcion=>{

              extrasvalor+=opcion.precio
            }

          )
        }
      })
      parcial+=PrecioBaseMenu;
      setTotalExtras(extrasvalor);
      setTotalMenu(parcial);
      console.log(`CALCULO DEL TOTAL DEL MENU ${parcial}`);
    }
    
    
    if (paso+1==6) setContinue(false);
    
    if (paso == 6){

      document.getElementById("slp").style.display = "initial";
      setCargando(true);
      getExtras();
      payAnticipo();


    } else handleAvanzaPaso();


  }

  const getSubscriptions = async (categoria: string) => {
    //document.getElementById("loaderEntrantes").style.display = "initial";
    const result = await ObexProjectsRequestHandler.get(`/ws/util.py/get_items_presupuestos_eventos?id_canal=1&codigo_pais=ES&categoria=${categoria}`);
    //document.getElementById("loaderEntrantes").style.display = "none";
    console.log("YA ESTA")
    
    const { status, listado } = result;
    if (status) {
      
      setPrimerentrante(listado[0].nombre)
      setEntranteToAdd(listado[0].nombre);
      setPriceEntranteToAdd(listado[0].precio);

      setOriglistaEntrantes(listado);

      const subscriptionList = listado.map(c => <><option value={c.id} selected={localityP == c.id }>{c.nombre}</option></>
      );
      
      //setsubscriptionL(subscriptionList);
      //setsubscriptionLF(subscriptionList);
    
    } else {
        console.error('ALGO HA IDO MAL')
    }

    var FranjasHorariasTemp = []
    FranjasHorariasTemp.push(<><option value='mañana'>Mañana</option></>);
    FranjasHorariasTemp.push(<><option value='tarde'>Tarde</option></>);
    FranjasHorariasTemp.push(<><option value='noche'>Noche</option></>);

    setFranjasHorarias(FranjasHorariasTemp);

    var placeholders = 0;
    var PlaceholdersText = [];
    if (subscriptions.length<3) {
      placeholders=(3-subscriptions.length);
      for (var conta=0;conta < placeholders;conta++){
        PlaceholdersText.push({'id':conta,'nombre':'Elige una opción'})
      }
      setPlaceholEntrantes(PlaceholdersText);
    } else {
      setPlaceholEntrantes([]);
    }


  }


  const getSubCategories = async (categoria) =>{

    const resultado = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/get_options?parent=${categoria}`);
    const {status, result } = resultado;
     var resultados =[]
    result.map(
       res=>{
         resultados.push(
           {
           "titulo":res.descripcion,
           "categoria":res.nombre,
           "precio_base": res.precio_base,
           "final": res.final
           }
         )
       }
    )
    setNextSubCategories(resultados);
   console.log(resultados)
   setloaderMenu(false);

  }

  const getMainCategories = async () =>{
   
    const resultado = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/get_main_categories`);
   const {status, result } = resultado;
    var resultados =[]
   result.map(
      res=>{
        resultados.push(
          {
          "titulo":res.descripcion,
          "categoria":res.nombre,
          "precio_base": res.precio_base
          }
        )
      }
   )
   setCategoriasPrincipales(resultados);
  console.log(resultados)
  }

  const getConfiguracion = async (categoria) =>{
    setlongDesplegables([]);
    setLoaderOpciones(true);
    const resultado = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/get_configuration?category=${categoria}`);
   const {status, result } = resultado;
    var pasos = []
    var fin_pasos = []
   result[0]['configuracion']['config'].map(
    c =>{
      pasos.push({"descripcion":c.descripcion,"nombre":c.tipo,"cantidad":c.cantidad,"asterisco":c.asterisco,"precio_base":c.precio_base})
    }
   )

   var opciones_menu = [];
   var opciones_place = [];
   var opciones_sel = [];
   var orig_opciones_menu = [];

  var primera_opcion = [];
  var opcionselect = [];
  var priceselect = [];
  var asteriscoselect = [];




   for (const pa in pasos){
      const rr = await ObexProjectsRequestHandler.get_items(pasos[pa]["nombre"]);
      fin_pasos.push({"descripcion": pasos[pa]["descripcion"], "asterisco":pasos[pa]["asterisco"],"precio_base":pasos[pa]["precio_base"],"id":pa,"tipo":pasos[pa]["nombre"],"listado":rr["listado"],"cantidad":pasos[pa]["cantidad"]})

      var subscriptionList = rr["listado"].map(c => <><option value={c.id} selected={localityP == c.id }>{c.nombre}</option></>
      );
      opciones_menu.push(subscriptionList)
      orig_opciones_menu.push({"descripcion": pasos[pa]["descripcion"],"asterisco":pasos[pa]["asterisco"],"precio_base":pasos[pa]["precio_base"],"id":pa,"tipo":pasos[pa]["nombre"],"listado":rr["listado"],"cantidad":pasos[pa]["cantidad"]});

      primera_opcion.push(rr["listado"][0]["nombre"])
      opcionselect.push(rr["listado"][0]["nombre"])
      priceselect.push(rr["listado"][0]["precio"])
      asteriscoselect.push(rr["listado"][0]["asteriscos"])

      opciones_sel.push([])

      var placeholders = 0;
      var PlaceholdersText = [];
      if (0<pasos[pa]["cantidad"]) {
        placeholders=(pasos[pa]["cantidad"]);

        for (var conta=0;conta < placeholders;conta++){
          PlaceholdersText.push({'id':conta,'nombre':'Elige una opción'})
        }
        opciones_place.push(PlaceholdersText);
      } else {
        opciones_place.push([]);
      }

      

  }

  setPrimerOpcion(primera_opcion)
  setOpcionToAdd(opcionselect);
  setPriceOpcionToAdd(priceselect);

  setAsteriscoToAdd(asteriscoselect);

  setOpcionesSeleccionadas(opciones_sel);
  setPlaceholEntrantes(opciones_place);
  setsubscriptionL(opciones_menu);
  setsubscriptionLF(opciones_menu);
  setOriglistaOpciones(orig_opciones_menu);

   console.log(fin_pasos);
   setlongDesplegables(fin_pasos);

   setLoaderOpciones(false);
   //document.getElementById("loaderEntrantes").style.display = "none";

  }



  const getLocalizaciones = async (fecha, horario, asistentes) => {
    setAgenda([]);
    var hora = horario.toLowerCase();
    var seated_reserve = asistentes;
    var standing_reserve = 0
    document.getElementById("loaderPlaces").style.display = "initial";
    const result = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/get_agenda_date?id_canal=1&codigo_pais=ES&date=${fecha}&hour=${hora}&seated_reserve=${seated_reserve}&standing_reserve=${standing_reserve}`);
    document.getElementById("loaderPlaces").style.display = "none";
    const { status, agenda } = result;
    if (status) {
      
      if (agenda.length>0)
      {
        setAgenda(agenda);
        const Localiza = agenda.map(
            c => <><option value={c.id} selected={localityP == c.id }>{c.nombre}</option></>
          );
        setListLocalizaziones(Localiza);
        
      }
      else {
        setAgenda([]);
        setListLocalizaziones([]);
        setLocalizacionElegida(0);
        setContinue(false);
      }
    } else {
        console.error('ALGO HA IDO MAL')
        setContinue(false);
    }
  }

  const getPrimerPlato = async () => {
    //document.getElementById("loaderPrincipales").style.display = "initial";
    const result = await ObexProjectsRequestHandler.get('/ws/util.py/get_items_presupuestos_eventos?id_canal=1&codigo_pais=ES&categoria=principal');
    document.getElementById("loaderPrincipales").style.display = "none";
    
    const { status, listado } = result;
    if (status) {
      
      setPrincipal(listado[0].nombre)
      setPrincipalToAdd(listado[0].nombre);
      setPricePrincipalToAdd(listado[0].precio);
      setOriglistaPrincipales(listado);
      const subscriptionList2 = listado.map(c => <><option value={c.id} selected={localityP == c.id }>{c.nombre}</option></>
      );
      setsubscriptionL2(subscriptionList2);    
    
    } else {
        console.error('ALGO HA IDO MAL')
    }

    var placeholders = 0;
    var PlaceholdersText = [];
    if (PlatosPrincipals.length<1) {
      placeholders=(1-PlatosPrincipals.length);
      for (var conta=0;conta < placeholders;conta++){
        PlaceholdersText.push({'id':conta,'nombre':'Elige una opción'})
      }
      setPlaceholPrincipales(PlaceholdersText);
    } else {
      setPlaceholPrincipales([]);
    }
    
  }

  const getPrimerPlatoUpdate = async () => {

    var placeholders = 0;
    var PlaceholdersText = [];
    if (PlatosPrincipals.length<1) {
      placeholders=(1-PlatosPrincipals.length);
      for (var conta=0;conta < placeholders;conta++){
        PlaceholdersText.push({'id':conta,'nombre':'Elige una opción'})
      }
      setPlaceholPrincipales(PlaceholdersText);
    } else {
      setPlaceholPrincipales([]);
    }


  }

  const getExtras = async () => {
    //document.getElementById("loaderExtras").style.display = "initial";
    const result = await ObexProjectsRequestHandler.get('/ws/util.py/get_items_presupuestos_eventos?id_canal=1&codigo_pais=ES&categoria=extras');
    document.getElementById("loaderExtras").style.display = "none";
    const { status, listado } = result;
    if (status) {
      
      setExtra(listado[0].nombre+" . . . EUR"+listado[0].precio+".00")
      setExtrasToAdd(listado[0].nombre);
      setPriceExtrasToAdd(listado[0].precio);
      setOriglistaExtras(listado);
      const subscriptionList3 = listado.map(c => <><option value={c.id} selected={localityP == c.id }>{c.nombre} . . . EUR {c.precio}.00</option></>
      );
      setsubscriptionL3(subscriptionList3);
    
    } else {
        console.error('ALGO HA IDO MAL')
    }
  }


  const getExtrasUpdate = async (id_listado) => {


    var placeTemp = PlaceholEntrantes;

    var placeholders = 0;
    var PlaceholdersText = [];
    if (opcionesSeleccionadas[id_listado].length<longDesplegables[id_listado]["cantidad"]) {
      placeholders=(longDesplegables[id_listado]["cantidad"]-opcionesSeleccionadas[id_listado].length);
      for (var conta=0;conta < placeholders;conta++){
        PlaceholdersText.push({'id':conta,'nombre':'Elige una opción'})
      }
      placeTemp[id_listado] = PlaceholdersText;
      setPlaceholEntrantes(placeTemp);
    } else {
      placeTemp[id_listado] = []
      setPlaceholEntrantes(placeTemp);
    }
    var ii = longDesplegables
    var tt=[]
    ii.map(cc=>{tt.push(cc)});

    setlongDesplegables([]);
    
    
    setlongDesplegables(tt);

  }

  const verificaContinue = async (e) => {

    if (paso == 0){
      if (Phone.length>8 && Name!="" && Email.includes('@') && Email.includes('.')){
        setContinue(true); 
      } else setContinue(false);
    }
    if (paso == 2){

    //if( subscriptions.length>2 && PlatosPrincipals.length>0 )  setContinue(true); else setContinue(false);

      var avanzar = true;

    //console.log(longDesplegables);
    //console.log(opcionesSeleccionadas);      

    longDesplegables.map(
      seccion=>{
        if (seccion.cantidad>opcionesSeleccionadas[seccion.id].length) avanzar=false;
      }
      
    )

    setContinue(avanzar);
    } 

    if (paso == 5){
      if (Asistentes>0 && LocalizacionElegida>0){
        setContinue(true); 
      } else setContinue(false);

    }

  }

  const handleLocalizacionElegida = (e) =>{
   
    setLocalizacionElegida(e.id);
    setlocalizacion_elegida(e.nombre);
    verificaContinue(e);
    setContinue(true);
  }

  const handleAsistentes = (e) => {

    const { value } = e.target;
    setAsistentes(value);
    getLocalizaciones(fecha_consulta,horario_elegido,value);
    
  }

  const handleName = (e) => {
    const { value } = e.target;
    setName(value);
    verificaContinue(e);
    if (Phone.toString().length>8 && value!="" && Email.includes('@') && Email.includes('.')){
      setContinue(true);} else setContinue(false);
  }

  const handleEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
    verificaContinue(e);

    if (Phone.toString().length>8 && Name!="" && value.includes('@') && value.includes('.')){
      setContinue(true);} else setContinue(false);
  }

  const handlePhone = (e) => {
    const { value } = e.target;
    if (value.length<10 && isNumber(value)) setPhone(value); else e.target.value=Phone;
    verificaContinue(e);
    if (value.toString().length>8 && Name!="" && Email.includes('@') && Email.includes('.')){
      setContinue(true);} else setContinue(false);
  }

  const setOpcionId = (event,id_listado) => {

    //console.log(event.target.value);
    //console.log(id_listado);
    //console.log(OriglistaOpciones);

    console.log(PlaceholEntrantes);
    console.log(opcionesSeleccionadas);

    var opcionesAnt = OpcionToAdd;
    var preciosAnt = PriceOpcionToAdd;
    var asteriscosAnt = AsteriscoToAdd;

    OriglistaOpciones[id_listado].listado.map(cus => {
      
      if (cus.id == event.target.value) 
      {

          opcionesAnt[id_listado] = cus.nombre;
          preciosAnt[id_listado] = cus.precio;
          asteriscosAnt[id_listado] = cus.asteriscos;
          console.log(cus.nombre);
          console.log(cus.precio);
          console.log(cus.asteriscos);


      }
    })

    setOpcionToAdd(opcionesAnt);
    setPriceOpcionToAdd(preciosAnt);
    setAsteriscoToAdd(asteriscosAnt);
    console.log("ASTERISCOS")
    console.log(asteriscosAnt)
        
  };


  const setEntrantesId = (event) => {

    OriglistaEntrantes.map(cus => {
      
      if (cus.id == event.target.value) 
      {
        setEntranteToAdd(cus.nombre);

        setPriceEntranteToAdd(cus.precio);
      }
    })
  };

  const setPrincipalId = (event) => {
    OriglistaPrincipales.map(cus => {
      if (cus.id == event.target.value) 
      {
        setPrincipalToAdd(cus.nombre);

        setPricePrincipalToAdd(cus.precio);

      }
    })
  };

  const setExtrasId = (event) => {
    OriglistaExtras.map(cus => {
      if (cus.id == event.target.value) 
      {
        setExtrasToAdd(cus.nombre);
      setPriceExtrasToAdd(cus.precio);
      }
    })
  };


  const handleEraseEntrante = async (event, client) => {

      event.preventDefault();
      event.stopPropagation();
      var EntrantesTemp = [];
      subscriptions.map(cus => {
        if (cus.id == client.id) 
        {

        } else {
          EntrantesTemp.push(cus);
        }
      })
      setSubscriptions(EntrantesTemp);

      verificaContinue(event);
      if (EntrantesTemp.length<3) setContinue(false);


            ////

            var ListaTemp = "";

            EntrantesTemp.map (opc=>{ ListaTemp+=opc.nombre; })
      
            var OpcionesEntrantes = []
      
              var nombre = '';
              var precio = 0.0;
              var notAdd =true;
              OriglistaEntrantes.map (cus => {
              if (ListaTemp.includes(cus.nombre)) {notAdd=true;} else {
                if (notAdd){
                  nombre = cus.nombre;
                  precio = cus.precio;
                  notAdd = false;
                }
                OpcionesEntrantes.push(<><option value={cus.id} selected={localityP == cus.id }>{cus.nombre}</option></>);
              }
            });
      
            if (nombre==""){nombre = OriglistaEntrantes[0].nombre;precio=OriglistaEntrantes[0].precio};
            setsubscriptionL(OpcionesEntrantes);
            setPrimerentrante(nombre)
            setEntranteToAdd(nombre);
            setPriceEntranteToAdd(precio);
      
            /////


      var placeholders = 0;
      var PlaceholdersText = [];
      if (EntrantesTemp.length<3) {
        placeholders=(3-(EntrantesTemp.length));
        for (var conta=0;conta < placeholders;conta++){
          PlaceholdersText.push({'id':conta,'nombre':'Elige una opción'})
        }
        setPlaceholEntrantes(PlaceholdersText);
      } else {
        setPlaceholEntrantes([]);
      }


    }
    

    const handleEraseOpcion = async (event, client, id_listado) => {

      event.preventDefault();
      event.stopPropagation();
      var tempSelecc = opcionesSeleccionadas;
      var EntrantesTemp = [];
      opcionesSeleccionadas[id_listado].map(cus => {
        if (cus.id == client.id) 
        {

        } else {
          EntrantesTemp.push(cus);
        }
      })

      tempSelecc[id_listado] = EntrantesTemp;

      setOpcionesSeleccionadas(tempSelecc);
      
      //setSubscriptions(EntrantesTemp);

      verificaContinue(event);
      if (EntrantesTemp.length<3) setContinue(false);

      /*
      var ListaTemp = "";

      OpcionesTemp[id_listado].map (opc=>{ ListaTemp+=opc.nombre; })
  
      var OpcionesEntrantes = []
  
        var nombre = '';
        var precio = 0.0;
        var notAdd =false;
        OriglistaOpciones[id_listado].listado.map (cus => {
        if (ListaTemp.includes(cus.nombre)) {notAdd=true;} else {
          if (notAdd){
            notAdd = false;
            nombre = cus.nombre;
            precio = cus.precio;
          }
          OpcionesEntrantes.push(<><option value={cus.id} selected={localityP == cus.id }>{cus.nombre}</option></>);
        }
      });
  
  
      var OPDisp = ListSubscription;
      OPDisp[id_listado] = OpcionesEntrantes
      setsubscriptionL(OPDisp);
  
  
      var po = PrimerOpcion;
      po[id_listado]=nombre;
      var opta = OpcionToAdd;
      opta[id_listado] = nombre;
      var popta = PriceOpcionToAdd;
      popta[id_listado]= precio;
  
      setPrimerOpcion(po)
      setOpcionToAdd(opta);
      setPriceOpcionToAdd(popta);
      */


            ////

            var ListaTemp = "";

            tempSelecc.map (opc=>{ ListaTemp+=opc.nombre; })
      
            var OpcionesEntrantes = []
      
              var nombre = '';
              var precio = 0.0;
              var asteriscos = 0;
              var notAdd =true;
              OriglistaOpciones[id_listado].listado.map (cus => {
              if (ListaTemp.includes(cus.nombre)) {notAdd=true;} else {
                if (notAdd){
                  nombre = cus.nombre;
                  precio = cus.precio;
                  asteriscos = cus.asteriscos;
                  notAdd = false;
                }
                OpcionesEntrantes.push(<><option value={cus.id} selected={localityP == cus.id }>{cus.nombre}</option></>);
              }
            });
      
            if (nombre==""){nombre = OriglistaOpciones[id_listado].listado[0].nombre;precio=OriglistaOpciones[id_listado].listado[0].precio; asteriscos = OriglistaOpciones[id_listado].listado[0].asteriscos};
            
            
            var OPDisp = ListSubscription;
            OPDisp[id_listado] = OpcionesEntrantes
            setsubscriptionL(OPDisp);


      var po = PrimerOpcion;
      po[id_listado]=nombre;
      var opta = OpcionToAdd;
      opta[id_listado] = nombre;
      var popta = PriceOpcionToAdd;
      popta[id_listado]= precio;

      var ast = AsteriscoToAdd;
      ast[id_listado]= asteriscos;

  
      setPrimerOpcion(po)
      setOpcionToAdd(opta);
      setPriceOpcionToAdd(popta);
      setAsteriscoToAdd(ast);
      

      getExtrasUpdate(id_listado);
            /////


  //    var placeholders = 0;
  //    var PlaceholdersText = [];
  //    if (EntrantesTemp.length<3) {
  //      placeholders=(3-(EntrantesTemp.length));
  //      for (var conta=0;conta < placeholders;conta++){
  //        PlaceholdersText.push({'id':conta,'nombre':'Elige una opción'})
  //      }
  //      setPlaceholEntrantes(PlaceholdersText);
  //    } else {
  //      setPlaceholEntrantes([]);
  //    }


    }

  const handleErasePrincipal = async (event, client) => {


    event.preventDefault();
    event.stopPropagation();
    var PrincipalTemp = [];
    PlatosPrincipals.map(cus => {
      if (cus.id == client.id) 
      {

      } else {
        PrincipalTemp.push(cus);
      }
    })
    setPlatosPrincipales(PrincipalTemp);

    verificaContinue(event);
    if (PrincipalTemp.length<1) setContinue(false);


    var placeholders = 0;
    var PlaceholdersText = [];
    if (PrincipalTemp.length<1) {
      placeholders=(1-PrincipalTemp.length);
      for (var conta=0;conta < placeholders;conta++){
        PlaceholdersText.push({'id':conta,'nombre':'Elige una opción'})
      }
      setPlaceholPrincipales(PlaceholdersText);
    } else {
      setPlaceholPrincipales([]);
    }


      ////

      var ListaTemp = "";

      PrincipalTemp.map (opc=>{ ListaTemp+=opc.nombre; })

      var OpcionesPrincipales = []

        var nombre = '';
        var precio = 0.0;
        var notAdd =false;
        OriglistaPrincipales.map (cus => {
        if (ListaTemp.includes(cus.nombre)) {notAdd=true;} else {
          if (notAdd){
            notAdd = false;
            nombre = cus.nombre;
            precio = cus.precio;
          }
          OpcionesPrincipales.push(<><option value={cus.id} selected={localityP == cus.id }>{cus.nombre}</option></>);
        }
      });
      if (nombre==""){nombre = OriglistaPrincipales[0].nombre;precio=OriglistaPrincipales[0].precio};
      setsubscriptionL2(OpcionesPrincipales);
      setPrincipal(nombre)
      setPrincipalToAdd(nombre);
      setPricePrincipalToAdd(precio);

      /////



  }

  const handleEraseExtra = async (event, client) => {

    event.preventDefault();
    event.stopPropagation();
    var ExtraTemp = [];
    Extras.map(cus => {
      if (cus.id == client.id) 
      {
      } else {
        ExtraTemp.push(cus);
      }
    })
    setListadoExtras(ExtraTemp);
    verificaContinue(event);

            ////

          var ListaTemp = "";

          ExtraTemp.map (opc=>{ ListaTemp+=opc.nombre; })
    
          var OpcionesExtras = []
    
            var nombre = '';
            var precio = 0.0;
            var notAdd =false;
            OriglistaExtras.map (cus => {
            if (ListaTemp.includes(cus.nombre)) {notAdd=true;} else {
              if (notAdd){
                notAdd = false;
                nombre = cus.nombre;
                precio = cus.precio;
              }
              OpcionesExtras.push(<><option value={cus.id} selected={localityP == cus.id }>{cus.nombre} . . . EUR {cus.precio}.00</option></>);
            }
          });
          if (nombre==""){nombre = OriglistaExtras[0].nombre;precio=OriglistaExtras[0].precio};
          setsubscriptionL3(OpcionesExtras);
          setExtra(nombre+" . . . EUR "+precio+".00");
          setExtrasToAdd(nombre);
          setPriceExtrasToAdd(precio);
    
          /////
  }


  const handleAddOpcion = (e, id_listado) =>{

    //console.log(id_listado);

    //console.log(OpcionToAdd[id_listado]);
    //console.log(PriceOpcionToAdd[id_listado]);

    
    var OpcionesTemp = opcionesSeleccionadas;


    var id = new Date();
    var ide = `${id.getHours()}${id.getMinutes()}${id.getSeconds()}${id.getMilliseconds()}`
    var elemento = {
      'id': ide,
      'nombre': OpcionToAdd[id_listado],
      'precio': PriceOpcionToAdd[id_listado],
      'asteriscos': AsteriscoToAdd[id_listado]
    };
    
    var lista = []
    ///console.log("IIIIIII")
    ///console.log(opcionesSeleccionadas[id_listado]);
    opcionesSeleccionadas[id_listado].map(cj=>{lista.push(cj)})
    ///console.log("HHH")
    lista.push(elemento);
    ///console.log(lista);
    var OpcionesTemp = opcionesSeleccionadas;
    OpcionesTemp[id_listado] = lista

    ///console.log("@@@@@");
    ///console.log(elemento);
    //console.log(OpcionesTemp);
    setOpcionesSeleccionadas(OpcionesTemp);

    ////

    var ListaTemp = "";

    OpcionesTemp[id_listado].map (opc=>{ ListaTemp+=opc.nombre; })

    var OpcionesEntrantes = []

      var nombre = '';
      var precio = 0.0;
      var notAdd =false;
      var asteriscos = 0;
      OriglistaOpciones[id_listado].listado.map (cus => {
      if (ListaTemp.includes(cus.nombre)) {notAdd=true;} else {
        if (notAdd){
          notAdd = false;
          nombre = cus.nombre;
          precio = cus.precio;
          asteriscos = cus.asteriscos;
        }
        OpcionesEntrantes.push(<><option value={cus.id} selected={localityP == cus.id }>{cus.nombre}</option></>);
      }
    });


    var OPDisp = ListSubscription;
    OPDisp[id_listado] = OpcionesEntrantes
    setsubscriptionL(OPDisp);


    var po = PrimerOpcion;
    po[id_listado]=nombre;
    var opta = OpcionToAdd;
    opta[id_listado] = nombre;
    var popta = PriceOpcionToAdd;
    popta[id_listado]= precio;

    var ast = AsteriscoToAdd;
    ast[id_listado]= asteriscos;


    setPrimerOpcion(po)
    setOpcionToAdd(opta);
    setPriceOpcionToAdd(popta);
    setAsteriscoToAdd(ast);

    /////

    verificaContinue(e);
    getExtrasUpdate(id_listado);

    
  }


  const handleAddEntrante = (e) =>{
    
      var EntrantesTemp = subscriptions;
      var id = new Date();
      var ide = `${id.getHours()}${id.getMinutes()}${id.getSeconds()}${id.getMilliseconds()}`
      var elemento = {
        'id': ide,
        'nombre': EntranteToAdd,
        'precio': PriceEntranteToAdd
      };
      
      EntrantesTemp.push(elemento);
      setSubscriptions(EntrantesTemp);

      ////

      var ListaTemp = "";

      EntrantesTemp.map (opc=>{ ListaTemp+=opc.nombre; })

      var OpcionesEntrantes = []

        var nombre = '';
        var precio = 0.0;
        var notAdd =false;
        OriglistaEntrantes.map (cus => {
        if (ListaTemp.includes(cus.nombre)) {notAdd=true;} else {
          if (notAdd){
            notAdd = false;
            nombre = cus.nombre;
            precio = cus.precio;
          }
          OpcionesEntrantes.push(<><option value={cus.id} selected={localityP == cus.id }>{cus.nombre}</option></>);
        }
      });

      setsubscriptionL(OpcionesEntrantes);
      setPrimerentrante(nombre)
      setEntranteToAdd(nombre);
      setPriceEntranteToAdd(precio);

      /////

      verificaContinue(e);
      getExtrasUpdate(1);
    }

  const handleAddExtra = (e) =>{
    var ExtrasTemp = Extras;
    var id = new Date();
    var ide = `${id.getHours()}${id.getMinutes()}${id.getSeconds()}${id.getMilliseconds()}`
    var elemento = {
      'id': ide,
      'nombre': ExtrasToAdd,
      'precio': PriceExtrasToAdd
    };
    
    ExtrasTemp.push(elemento);
    setListadoExtras(ExtrasTemp);

          ////

          var ListaTemp = "";

          ExtrasTemp.map (opc=>{ ListaTemp+=opc.nombre; })
    
          var OpcionesExtras = []
    
            var nombre = '';
            var precio = 0.0;
            var notAdd =false;
            OriglistaExtras.map (cus => {
            if (ListaTemp.includes(cus.nombre)) {notAdd=true;} else {
              if (notAdd){
                notAdd = false;
                nombre = cus.nombre;
                precio = cus.precio;
              }
              OpcionesExtras.push(<><option value={cus.id} selected={localityP == cus.id }>{cus.nombre} . . . EUR {cus.precio}.00</option></>);
            }
          });
    
          setsubscriptionL3(OpcionesExtras);
          setExtra(nombre+" . . . EUR "+precio+".00")
          setExtrasToAdd(nombre);
          setPriceExtrasToAdd(precio);
    
          /////


    verificaContinue(e);
    getPrimerPlatoUpdate();
  }

  const handleAddPrincipal = (e) =>{
    var PrincipalTemp = PlatosPrincipals;
    var id = new Date();
    var ide = `${id.getHours()}${id.getMinutes()}${id.getSeconds()}${id.getMilliseconds()}`
    var elemento = {
      'id': ide,
      'nombre': PrincipalToAdd,
      'precio': PricePrincipalToAdd
    };
    
    PrincipalTemp.push(elemento);
    setPlatosPrincipales(PrincipalTemp);

    verificaContinue(e);
    getPrimerPlatoUpdate();


          ////

          var ListaTemp = "";

          PrincipalTemp.map (opc=>{ ListaTemp+=opc.nombre; })
    
          var OpcionesPrincipales = []
    
            var nombre = '';
            var precio = 0.0;
            var notAdd =false;
            OriglistaPrincipales.map (cus => {
            if (ListaTemp.includes(cus.nombre)) {notAdd=true;} else {
              if (notAdd){
                notAdd = false;
                nombre = cus.nombre;
                precio = cus.precio;
              }
              OpcionesPrincipales.push(<><option value={cus.id} selected={localityP == cus.id }>{cus.nombre}</option></>);
            }
          });
    
          setsubscriptionL2(OpcionesPrincipales);
          setPrincipal(nombre)
          setPrincipalToAdd(nombre);
          setPricePrincipalToAdd(precio);  
          /////
  }

  const registerStep = async (inicio,tipoEvento) => {


    var Nombre = '';
    var Correo = '';
    var Telef = ''
    if (Name=="") Nombre = Name; else Nombre = Name;
    if (Email=="") Correo = Email; else Correo = Email;
    if (Phone=="") Telef = Phone; else Telef = Phone;

    var listaEntrantesMarcados = "{'entrantes':[";

    subscriptions.map(client => {listaEntrantesMarcados+="'"+client.nombre+"',"});

    listaEntrantesMarcados+="]}";

    var listaPrincipalMarcados = "{'principales':[";

    PlatosPrincipals.map(client => {listaPrincipalMarcados+="'"+client.nombre+"',"});

    listaPrincipalMarcados+="]}";

    var listaExtrasMarcados = "{'extras':[";

    Extras.map(client => {listaExtrasMarcados+="'"+client.nombre+"',"});

    listaExtrasMarcados+="]}";

    var d = new Date();
    var n = d.toLocaleTimeString();

    var opciones = "{}"
    var listaopcionesmarcadas = "{'opciones':[";
    //console.log("++++++++++++++++")

    longDesplegables.map(
      seccion=>{

        listaopcionesmarcadas+="{'"+seccion.tipo+"':[";
        opcionesSeleccionadas[seccion.id].map(
          opcion=>{
            //console.log(opcion);
            listaopcionesmarcadas+="'"+opcion.nombre+"',"
          }

        )
        listaopcionesmarcadas+="]},";
      }
    )
    listaopcionesmarcadas+="]}";
    //console.log("---------------")
    //console.log(listaopcionesmarcadas);
    //console.log("===============")
    const result = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/register_event_agenda_incompleto?id_canal=1&codigo_pais=ES&name=${Nombre}&email=${Correo}&phone=${Telef}&tipo_reserva=${tipoEvento}&entrantes=${listaEntrantesMarcados}&pases={}&primeros=${listaPrincipalMarcados}&extras=${listaExtrasMarcados}&reserva_sentado=${Asistentes}0&reserva_pie=0&fecha_reserva=${fecha_consulta}&franja_horaria=${horario_elegido}&id_localizacion=${LocalizacionElegida}&sesion=${Session}&iniciosesion=${inicio}&fecha_registro=${getToday()}&hora_registro=${n}&opciones=${listaopcionesmarcadas}`);


  }

  const handleNextParent = (categoria) =>{
    setloaderMenu(true);
    setNextSubCategories([]);
    getSubCategories(categoria);
    //
  }


  useEffect(() => {
    var sesion = generateId();
    setSession(sesion);
    getMainCategories();
    //registerStep(false,'evento');
    var body = document.getElementsByTagName('body')[0];
    body.style.fontFamily="Regular Intro Book Alt";
    body.style.backgroundColor="lightgray";
  }, [])

  return(

    <>

    


        
        <Container fluid className="text-center d-flex flex-column min-vh-100">

            <Row className="justify-content-center  min-vh-100">
            <div className="min-vh-100" id="slp" style={{ height: '100%', width: '100%', position: 'fixed', backgroundColor: '#00000070', zIndex: 9999, opacity: '100%', justifyContent: 'center' ,display:'none'}}>
          <div className='loader' style={{ zIndex: 99999, opacity: '100%', marginTop: '20%', color:'orange' }}></div>
      </div>
              <div className="rounded px-5 pb-4" style={{ minWidth: '400px', maxWidth: '800px', backgroundColor: "white" }}>
                <Row className="justify-content-center"
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingLeft: 0,
                    paddingRight: 0,
                    marginRight: "-60px",
                    marginLeft: "-60px",
                    marginBottom: "3px"
                  }}

                >
                  <Col>
                    <Image
                      style={{ height: '200px' }}
                      src={Logo2} />
                  </Col>
                </Row>


                <Row className="justify-content-center"
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingLeft: 0,
                    paddingRight: 0,
                    marginRight: "-60px",
                    marginLeft: "-60px",
                    marginBottom: "3px"
                  }}

                >
                  <Col>
                    {paso == 0 ?
                      <Image
                        style={{ height: '20px' }}
                        src={pasos1} /> :
                      <></>}
                      {paso == 1 ?
                      <Image
                        style={{ height: '20px' }}
                        src={pasos2} /> :
                      <></>}
                    {paso == 2 ?
                      <Image
                        style={{ height: '20px' }}
                        src={pasos3} /> :
                      <></>}
                    {paso == 3 ?
                      <Image
                        style={{ height: '20px' }}
                        src={pasos4} /> :
                      <></>}
                    {paso == 4 || paso == 5 ?
                      <Image
                        style={{ height: '20px' }}
                        src={pasos5} /> :
                      <></>}
                    {paso == 6 ?
                      <Image
                        style={{ height: '20px' }}
                        src={pasos6} /> :
                      <></>}
                  </Col>
                </Row>

                {/**************************************************************************************** Datos de contacto */}

                {paso == 0 ?
                  <><div style={{ marginTop: '40px', fontSize: 'large' }}>
                    <a style={{ color: 'gray' }}>Bienvenido al</a> <a style={{ fontStyle: 'italic' }}>configurador de eventos y cotizador digital</a><a style={{ color: 'gray' }}> de </a>
                  </div>
                    <div style={{ fontSize: 'large', color: 'gray' }}>La Gloria de Santo Domingo</div>
                    <div style={{ fontSize: 'large', marginTop: '10px' }}>¡Queremos Conocerte!</div>
                    <Form style={{ marginTop: '40px', marginLeft: '14%', marginRight: '14%' }}>
                      <Form.Group controlId="formBasicEmail">
                        <Row style={{ borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px', width: '100%', marginLeft: '0px', borderColor: '#ced4da' }}>
                          <Col style={{ maxWidth: '25px', display: 'contents' }}><Image src={iconoNombre} style={{ height: '23px', width: '20px', marginTop: '6px', marginLeft: '10px' }} /></Col>
                          <Col style={{ marginLeft: '-15px' }}><Form.Control style={{ borderStyle: 'hidden', boxShadow: 'none' }} onChange={handleName} required type="string" placeholder="Nombre" value={Name} /></Col>

                        </Row>
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword">
                        <Row style={{ borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px', width: '100%', marginLeft: '0px', borderColor: '#ced4da' }}>
                          <Col style={{ maxWidth: '25px', display: 'contents' }}><Image src={iconoCorreo} style={{ height: '17px', width: '20px', marginTop: '10px', marginLeft: '10px' }} /></Col>
                          <Col style={{ marginLeft: '-15px' }}><Form.Control style={{ borderStyle: 'hidden', boxShadow: 'none' }} onChange={handleEmail} required type="email" placeholder="Correo electrónico" value={Email} /></Col>
                        </Row>
                      </Form.Group>
                      <Form.Group controlId="formBasicPhone">
                        <Row style={{ borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px', width: '100%', marginLeft: '0px', borderColor: '#ced4da' }}>
                          <Col style={{ maxWidth: '25px', display: 'contents' }}><Image src={iconoTelefono} style={{ height: '23px', width: '15px', marginTop: '6px', marginLeft: '12px', marginRight: '3px' }} /></Col>
                          <Col style={{ marginLeft: '-15px' }}><Form.Control style={{ borderStyle: 'hidden', boxShadow: 'none' }} onChange={handlePhone} required type="phone" placeholder="Teléfono de contacto" value={Phone} /></Col>
                        </Row>
                      </Form.Group>
                    </Form></>
                  :
                  <></>}

                {/*********************************************************************************** ************************/}

                {paso == 1 ? 
                
                <>
<Row className="justify-content-center"
                style={{
                  backgroundColor: "white",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginRight: "0px",
                  marginLeft: "0px",
                  marginBottom: "3px"
                }}

              >
                <Col>

                  <Image
                    style={{ height: '80px' }}
                    src={comida} />
                </Col>
              </Row>



              <div style={{ marginTop: '40px', fontSize: 'large' }}>
                <a style={{ color: 'gray' }}>En el configurador y cotizador digital de la Gloria podrás</a> <div style={{ fontStyle: 'italic' }}>elegir tu menú o tus cócteles, el lugar, la fecha y el horario</div><a style={{ color: 'gray' }}> en 6 sencillos pasos.</a>
              </div>
              <div style={{ fontSize: 'large', color: 'gray', marginTop: '10px', marginBottom:'90px' }}>¡Configura tu evento y recibe una cotización al instante!</div>


              {loaderMenu ?
                    <Col style={{ justifyContent: 'center', display: 'flex' }}>
                                <Row id="loaderMenu" className="loader" style={{ display: 'initial', marginTop: '30px', color: 'orange' }}></Row>
                              </Col>:<></>
                    }


              { !Secundarias ?
              
                CategoriasPrincipales.map(categoria=>{
                  return(
                  <Row style={{
                    color: "white",
                    backgroundColor: "#969593",
                    justifyContent: "center",
                    paddingTop: "7px",
                    paddingBottom: "4px",
                    marginBottom: "1em",
                    marginRight: "80px",
                    marginLeft: "80px",
                    fontSize: "14px",
                    marginTop: '10px',
                    cursor: 'pointer',
                    borderRadius: '5px'
                  }}
                    onClick={(event) => {
    
                      event.preventDefault();
                      event.stopPropagation();

                      var tempArbol = arbolMenu;
                      tempArbol.push(categoria.categoria);
                      setarbolMenu(tempArbol);

                      setSecundarias(true);
                      handleNextParent(categoria.categoria)
                      //handleAvanzaPaso(categoria.categoria);
    
                    } }>
                    {categoria.titulo}
                  </Row>

                  )
                }
                )
              :
              
              NextSubCategories.map(categoria=>{
                  return(
                  <Row style={{
                    color: "white",
                    backgroundColor: "#969593",
                    justifyContent: "center",
                    paddingTop: "7px",
                    paddingBottom: "4px",
                    marginBottom: "1em",
                    marginRight: "80px",
                    marginLeft: "80px",
                    fontSize: "14px",
                    marginTop: '10px',
                    cursor: 'pointer',
                    borderRadius: '5px'
                  }}
                    onClick={(event) => {
                      
                      console.log(arbolMenu)

                      event.preventDefault();
                      event.stopPropagation();

                      if (categoria.final){
                        setContinue(true);
                        handleAvanzaPaso(categoria.categoria);
                        //console.log(categoria);
                        setTituloMenu(categoria.titulo);
                        setPrecioBaseMenu(categoria.precio_base);
                        setCategoriaEvento(categoria.categoria);

                      } else {
                        var tempArbol = arbolMenu;
                        tempArbol.push(categoria.categoria);
                        setarbolMenu(tempArbol);
                      setSecundarias(true);
                      handleNextParent(categoria.categoria)
     
                      }
    
                    } }>
                    {categoria.titulo}
                  </Row>

                  )
                }
                )
              
              
              
              
              
              
            }

              </>
                
                
                :
                
                <></>}

                {/*********************************************************************************** Datos del Presuepuesto */}

                {paso == 2 ?
                  <><><>


                    {/****** Entrantes ******************************************************************************************************************+ */}


                    <span style={{ marginTop: '40px', marginBottom: '20px', fontSize: '17px', fontWeight: 'bold' }}><h5>La composición del menú para {TituloMenu} es:</h5></span>
                    <div style={{ backgroundColor: 'lightgray', height: '1px', width: '100%', marginBottom: '60px' }}></div>

                    {loaderOpciones ?
                    <Col style={{ justifyContent: 'center', display: 'flex' }}>
                                <Row id="loaderEntrantes" className="loader" style={{ display: 'initial', marginTop: '30px', color: 'orange' }}></Row>
                              </Col>:<></>
                    }

                    {longDesplegables.map( paso=>{
                      console.log(paso);
                      return(
                        
                    <><Row style={{ backgroundColor: '#f3f4f4', borderColor: '#dfdfdf', borderWidth: '1px', borderStyle: 'solid' }}>

                          <Col>

                            <Row className="justify-content-center"
                              style={{
                                marginTop: '-15px'
                              }}

                            >
                              <Col>
                              { paso.id == 0 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={uno} />:<></>}
                              { paso.id == 1 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={dos} />:<></>}
                              { paso.id == 2 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={tres} />:<></>}
                              { paso.id == 3 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={cuatro} />:<></>}
                              { paso.id == 4 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={cinco} />:<></>}
                              { paso.id == 5 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={seis} />:<></>}
                              { paso.id == 6 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={siete} />:<></>}
                              { paso.id == 7 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={ocho} />:<></>}
                              { paso.id == 8 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={nueve} />:<></>}
                              { paso.id == 9 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={diez} />:<></>}

                              </Col>
                            </Row>


                            <><Row style={{ justifyContent: 'center', marginTop: '30px' }}><h5>Selecciona tus {paso.descripcion}</h5></Row><Row style={{ justifyContent: 'center', marginTop: '-10px', color: 'gray', fontSize: 'large' }}>Elige {numerales[paso.cantidad]} opciones de nuestro menú.</Row><Row className="planSelector" style={{ marginLeft: '2%', backgroundColor: '#8d8d8d', justifyContent: 'center', marginTop: '20px', color: 'white', height: '40px', width: '96%', borderRadius: '5px' }}>

                              <Col style={{ width: '90%', }}>


                                <Form className="obex-form">

                                  <Form.Group controlId="formBasicEntrante">
                                    <Form.Control className="planSelectorControl" style={{ color: 'white', backgroundColor: '#8d8d8d', borderStyle: 'none' }} required as="select" placeholder='Plan' custom onChange={(event) => { setOpcionId(event, paso.id); } }>
                                      <option value="" selected disabled hidden>{PrimerOpcion[paso.id]}</option>
                                      {ListSubscription[paso.id]}
                                    </Form.Control>
                                  </Form.Group>

                                </Form>
                              </Col>



                              <Col style={{ width: '0%', flex: 'inherit' }}>
                                <button className="btn-transparent" style={{ float: 'right', marginTop: '8px' }}><FontAwesomeIcon icon={faPlusCircle} onClick={(e) => { handleAddOpcion(e, paso.id); } } /> </button>
                              </Col>
                            </Row><Col style={{ justifyContent: 'center', display: 'flex' }}>
                                <Row id="loaderEntrantes" className="loader" style={{ display: 'none', marginTop: '30px', color: 'orange' }}></Row>
                              </Col><Row style={{ backgroundColor: '#f3f4f4', /*minHeight: '240px'*/ }}>

                                <Row id={"selector_" + (0).toString()} style={{ marginTop: '20px', marginBottom: '80px', /*minHeight: '240px',*/ marginLeft: '10px', marginRight: '10px', minWidth:'97%' }}>


                                  


                                  {opcionesSeleccionadas[paso.id].map(client => {
                                    return (

                                      <Row style={{ backgroundColor: 'white', marginLeft: '2.5%', width: '95%', minHeight: '40px', maxHeight: '55px', paddingTop: '10px', textAlign: 'center', overflow: 'hidden', borderColor: 'lightgray', borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px', marginBottom: '10px' }}>

                                        <Col style={{ width: '90%', }}>
                                          <h6>{client.nombre}</h6>
                                        </Col>
                                        <Col style={{ width: '0%', flex: 'inherit', marginRight: '20px' }}>

                                          <Button style={{ color: '#aaaaaa' }} onClick={(event) => handleEraseOpcion(event, client, paso.id)} type="submit" className="btn-transparent">
                                            <FontAwesomeIcon icon={faTrash} />
                                          </Button>

                                        </Col>
                                      </Row>
                                    );
                                  }
                                  )}

                                  
                                  {PlaceholEntrantes[paso.id].map(client => {
                                    return (
                                      <Row style={{ fontSize: '15px', marginLeft: '2.5%', width: '95%', color: 'lightgray', backgroundColor: 'white', minHeight: '40px', maxHeight: '55px', paddingTop: '10px', textAlign: 'center', overflow: 'hidden', borderColor: 'lightgray', borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px', marginBottom: '10px' }}>
                                        <Col style={{ width: '90%', }}>
                                          {client.nombre}
                                        </Col>
                                      </Row>
                                    );
                                  }
                                  )}
                                  {paso.precio_base > 0 ?
                                    <>
                                      <div style={{ color: 'gray', marginLeft: '10%', marginRight: '10%', marginTop:'20px' }}>
                                        Si usted desea añadir {paso.tipo} extra (más de {numerales[paso.cantidad]}) cada uno tendrá un costo adicional de {paso.precio_base}.00 EUR.
                                      </div>
                                      <div style={{ color: 'gray', marginLeft: '10%', marginRight: '10%', marginTop: '0px', marginBottom:'-40px' }}>
                                        Los platos seleccionados que se acompañan de asteriscos, tienen un costo adicional de {paso.asterisco}.00 EUR por asterisco.
                                      </div>
                                    </>
                                    :
                                    <>
                                    </>}
                                </Row>
                              </Row></>
                          </Col>
                        </Row>
                        <Row>
                          <Col style={{height:'80px'}}></Col>
                          </Row></>

                      )

                        })}



                      {/* ************** Selector de Plato Principal *******************************************************************************/}



                      




                      {/*****Extras ********************************************************************************************** */}


                    </></><>

                      


                      {/*** RESTO DE TEXTOS */}


                      <div style={{ backgroundColor: 'white', height: '20px', width: '100%', marginBottom: '0px' }}></div>
                      <Row style={{ backgroundColor: '#f3f4f4', borderColor: '#dfdfdf', borderWidth: '1px', borderStyle: 'solid' }}>
                        <Col>
                          <Row className="justify-content-center" style={{ marginTop: '-15px' }}>
                            <Col>

                          {  longDesplegables.length==1 ?
                              <Image style={{ height: '30px' }} src={dos} />:<></>
                          }
                          {  longDesplegables.length==2 ?
                              <Image style={{ height: '30px' }} src={tres} />:<></>
                          }
                          {  longDesplegables.length==3 ?
                              <Image style={{ height: '30px' }} src={cuatro} />:<></>
                          }
                          {  longDesplegables.length==4 ?
                              <Image style={{ height: '30px' }} src={cinco} />:<></>
                          }
                          {  longDesplegables.length==5 ?
                              <Image style={{ height: '30px' }} src={seis} />:<></>
                          }
                          {  longDesplegables.length==6 ?
                              <Image style={{ height: '30px' }} src={siete} />:<></>
                          }
                          {  longDesplegables.length==7 ?
                              <Image style={{ height: '30px' }} src={ocho} />:<></>
                          }
                          {  longDesplegables.length==8 ?
                              <Image style={{ height: '30px' }} src={nueve} />:<></>
                          }
                          {  longDesplegables.length==9 ?
                              <Image style={{ height: '30px' }} src={diez} />:<></>
                          }
                          {  longDesplegables.length==10 ?
                              <Image style={{ height: '30px' }} src={once} />:<></>
                          }
                            
                            
                            </Col>
                          </Row>
                          <Row style={{ justifyContent: 'center', marginTop: '30px' }}><h5>Degustación de postres</h5></Row>
                          <Row style={{ justifyContent: 'center', marginTop: '-10px', marginBottom: '30px', color: 'gray', fontSize: 'large' }}>Elaboración especial de la Gloria de Santo Domingo.</Row>
                        </Col>
                      </Row>


                      <div style={{ backgroundColor: 'white', height: '20px', width: '100%', marginBottom: '60px' }}></div>
                      <Row style={{ backgroundColor: '#f3f4f4', borderColor: '#dfdfdf', borderWidth: '1px', borderStyle: 'solid' }}>
                        <Col>
                          <Row className="justify-content-center" style={{ marginTop: '-15px' }}>
                            <Col>


                            {  longDesplegables.length==1 ?
                              <Image style={{ height: '30px' }} src={tres} />:<></>
                          }
                          {  longDesplegables.length==2 ?
                              <Image style={{ height: '30px' }} src={cuatro} />:<></>
                          }
                          {  longDesplegables.length==3 ?
                              <Image style={{ height: '30px' }} src={cinco} />:<></>
                          }
                          {  longDesplegables.length==4 ?
                              <Image style={{ height: '30px' }} src={seis} />:<></>
                          }
                          {  longDesplegables.length==5 ?
                              <Image style={{ height: '30px' }} src={siete} />:<></>
                          }
                          {  longDesplegables.length==6 ?
                              <Image style={{ height: '30px' }} src={ocho} />:<></>
                          }
                          {  longDesplegables.length==7 ?
                              <Image style={{ height: '30px' }} src={nueve} />:<></>
                          }
                          {  longDesplegables.length==8 ?
                              <Image style={{ height: '30px' }} src={diez} />:<></>
                          }
                          {  longDesplegables.length==9 ?
                              <Image style={{ height: '30px' }} src={once} />:<></>
                          }
                          {  longDesplegables.length==10 ?
                              <Image style={{ height: '30px' }} src={doce} />:<></>
                          }


                            </Col>
                          </Row>
                          <Row style={{ justifyContent: 'center', marginTop: '30px' }}><h5>Cerveza de grifo, vino tinto y blanco, refrescos y agua</h5></Row>
                          <Row style={{ justifyContent: 'center', marginTop: '-10px', marginBottom: '30px', color: 'gray', fontSize: 'large' }}>La bebida se sirve en formato de barra libre y se inicia con el servicio de entrantes y finaliza con el postre.</Row>
                        </Col>
                      </Row>


                      {/*** FINAL DE TEXTOS */}

                    </></>

                  :
                  <></>}

                {paso == 3 ?
                  <><><div>
                    <Row style={{ width: '899px' }}></Row>
                    <Row className="mb-3" style={{ marginTop: '40px' }}><Col><h5>RESUMEN DE SU CONTRATACIÓN</h5>
                      <Row style={{ backgroundColor: '#f3f4f4', borderColor: '#dfdfdf', borderWidth: '1px', borderStyle: 'solid', marginTop: '20px' }}></Row>
                    </Col></Row>


                    {longDesplegables.map( paso=>{
                      if (opcionesSeleccionadas[paso.id].length>0) return(
                        <Row className="justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>




                              <Col>
                              { paso.id == 0 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={uno} />:<></>}
                              { paso.id == 1 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={dos} />:<></>}
                              { paso.id == 2 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={tres} />:<></>}
                              { paso.id == 3 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={cuatro} />:<></>}
                              { paso.id == 4 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={cinco} />:<></>}
                              { paso.id == 5 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={seis} />:<></>}
                              { paso.id == 6 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={siete} />:<></>}
                              { paso.id == 7 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={ocho} />:<></>}
                              { paso.id == 8 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={nueve} />:<></>}
                              { paso.id == 9 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={diez} />:<></>}


                              <Row className="mb-3" style={{marginTop:'25px'}}><Col><h5>{paso.tipo.toUpperCase()}</h5></Col></Row>


                              {opcionesSeleccionadas[paso.id].map(client => {
                                return (
                                  <Row className="mb-3" style={{}}>
                                    <Col className="">
                                      <span><h6>{client.nombre}</h6></span>
                                    </Col>
                                  </Row>
                                );

                              })}


                        </Col>
                        </Row>
                      )
                    })}




{/*
                    <Row className="justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>
                      <Col>
                        <Image style={{ height: '30px' }} src={uno} />
                      </Col>
                    </Row>


                    <Row className="mb-3" style={{}}><Col><h5>ENTRANTES</h5></Col></Row>
                    {subscriptions.map(client => {
                      return (
                        <Row className="mb-3" style={{}}>
                          <Col className="">
                            <span><h6>{client.nombre}</h6></span>
                          </Col>
                        </Row>
                      );

                    })}

                    <Row className="justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>
                      <Col>
                        <Image style={{ height: '30px' }} src={dos} />
                      </Col>
                    </Row>

                    <Row className="mb-3" style={{}}><Col><h5>PLATO PRINCIPAL</h5></Col></Row>
                    {PlatosPrincipals.map(client => {
                      return (
                        <Row className="mb-3" style={{}}>
                          <Col className="">
                            <span><h6>{client.nombre}</h6></span>
                          </Col>
                        </Row>
                      );

                    })}

                    {Extras.length > 0 ?

                      <><Row className="justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>
                        <Col>
                          <Image style={{ height: '30px' }} src={tres} />
                        </Col>
                      </Row><Row className="mb-3" style={{}}><Col><h5>SERVICIOS EXTRA</h5></Col></Row></> : <></>}
                    {Extras.map(client => {
                      return (
                        <Row className="mb-3" style={{}}>
                          <Col className="">
                            <span><h6>{client.nombre}</h6></span>
                          </Col>
                        </Row>
                      );

                    })}<></>

                  */}

                    <Row className="justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>
                      <Col>
                      {  TotalSecs==1 ?
                              <Image style={{ height: '30px' }} src={dos} />:<></>
                          }
                          {  TotalSecs==2 ?
                              <Image style={{ height: '30px' }} src={tres} />:<></>
                          }
                          {  TotalSecs==3 ?
                              <Image style={{ height: '30px' }} src={cuatro} />:<></>
                          }
                          {  TotalSecs==4 ?
                              <Image style={{ height: '30px' }} src={cinco} />:<></>
                          }
                          {  TotalSecs==5 ?
                              <Image style={{ height: '30px' }} src={seis} />:<></>
                          }
                          {  TotalSecs==6 ?
                              <Image style={{ height: '30px' }} src={siete} />:<></>
                          }
                          {  TotalSecs==7 ?
                              <Image style={{ height: '30px' }} src={ocho} />:<></>
                          }
                          {  TotalSecs==8 ?
                              <Image style={{ height: '30px' }} src={nueve} />:<></>
                          }
                          {  TotalSecs==9 ?
                              <Image style={{ height: '30px' }} src={diez} />:<></>
                          }
                          {  TotalSecs==10 ?
                              <Image style={{ height: '30px' }} src={once} />:<></>
                          }
                      </Col>
                    </Row>
                    <Row className="mb-3" style={{}}><Col><h5>POSTRES</h5></Col></Row>
                    <Row className="mb-3" style={{}}>
                      <Col className="">
                        <span><h6>Degustación de postres de nuestra elaboración.</h6></span>
                      </Col>
                    </Row>


                    <Row className="justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>
                      <Col>
                      {  TotalSecs==1 ?
                              <Image style={{ height: '30px' }} src={tres} />:<></>
                          }
                          {  TotalSecs==2 ?
                              <Image style={{ height: '30px' }} src={cuatro} />:<></>
                          }
                          {  TotalSecs==3 ?
                              <Image style={{ height: '30px' }} src={cinco} />:<></>
                          }
                          {  TotalSecs==4 ?
                              <Image style={{ height: '30px' }} src={seis} />:<></>
                          }
                          {  TotalSecs==5 ?
                              <Image style={{ height: '30px' }} src={siete} />:<></>
                          }
                          {  TotalSecs==6 ?
                              <Image style={{ height: '30px' }} src={ocho} />:<></>
                          }
                          {  TotalSecs==7 ?
                              <Image style={{ height: '30px' }} src={nueve} />:<></>
                          }
                          {  TotalSecs==8 ?
                              <Image style={{ height: '30px' }} src={diez} />:<></>
                          }
                          {  TotalSecs==9 ?
                              <Image style={{ height: '30px' }} src={once} />:<></>
                          }
                          {  TotalSecs==10 ?
                              <Image style={{ height: '30px' }} src={doce} />:<></>
                          }
                      </Col>
                    </Row>
                    <Row className="mb-3" style={{}}><Col><h5>BEBIDA</h5></Col></Row>
                    <Row className="mb-3" style={{}}>
                      <Col className="">
                        <span><h6>Cerveza de grifo, vino tinto y blanco, refrescos y agua.</h6></span>
                      </Col>
                    </Row>


                  </div>


                    <div style={{ backgroundColor: 'white', height: '20px', width: '100%', marginBottom: '60px' }}></div>
                    <Row style={{ backgroundColor: '#f3f4f4', borderColor: '#dfdfdf', borderWidth: '1px', borderStyle: 'solid' }}>
                      <Col>

                        <Row style={{ justifyContent: 'init', marginTop: '30px', fontSize: 'medium' }}>
                          <Col style={{ textAlign: 'initial' }}>Precio del menú</Col>
                          <Col style={{ textAlign: 'end' }}>EUR {totalMenu}.00</Col>
                        </Row>
                        <Row style={{ justifyContent: 'init', marginTop: '-5px', marginBottom: '30px', fontSize: 'medium' }}>
                          <Col style={{ textAlign: 'initial' }}>Precio de los servicios extra.</Col>
                          <Col style={{ textAlign: 'end' }}>EUR {totalExtras}.00</Col>
                        </Row>
                      </Col>
                    </Row>

                  </>

                  </>
                  :
                  <></>}


                {paso == 4 ?
                  <>
                    <Row style={{ width: '899px' }}></Row>
                    <Row style={{ justifyContent: 'center', color: '#8d8d8d', fontSize: '15px', marginBottom: '20px' }}>Selecciona la <span style={{ color: '#626262', fontSize: '15px', fontStyle: 'italic', marginLeft: '5px', marginRight: '5px' }}> fecha </span> <span style={{ color: '#8d8d8d', fontSize: '15px' }}> para tu evento</span></Row>
                    <Row style={{ justifyContent: 'center' }}>
                      <Calendar
                        onChange={(event) => {
                          var ff = new Date(event.getTime() - (event.getTimezoneOffset() * 60000));

                          var fecha_temp = ff.toISOString().split('T')[0];
                          handleFecha(event, fecha_temp);
                          onChangeCalendar(event);
                        } }
                        value={valueCalendar} />

                    </Row>

                    <Row style={{ justifyContent: 'center', color: '#8d8d8d', fontSize: '15px', marginBottom: '20px', marginTop: '20px' }}>Selecciona la <span style={{ color: '#626262', fontSize: '15px', fontStyle: 'italic', marginLeft: '5px', marginRight: '5px' }}> franja temporal </span> <span style={{ color: '#8d8d8d', fontSize: '15px' }}> para tu evento</span></Row>
                    <Row style={{ marginTop: '60px', marginLeft: '10%', marginRight: '10%', justifyContent: 'center' }}>
                      <Col>

                        <Row style={{ justifyContent: 'center' }} onClick={(event) => { handleHora(event, 'mañana'); } }>
                          {horario_elegido == "mañana" ?
                            <Image
                              style={{ height: '60px' }}
                              src={icon_mañana_sel} /> :
                            <Image
                              style={{ height: '60px' }}
                              src={icon_mañana} />}
                        </Row>
                        <Row style={{ justifyContent: 'center', marginTop: '10px' }}>
                          Mañana
                        </Row>
                      </Col>
                      <Col>
                        <Row style={{ justifyContent: 'center' }} onClick={(event) => { handleHora(event, 'tarde'); } }>
                          {horario_elegido == "tarde" ?
                            <Image
                              style={{ height: '60px' }}
                              src={icon_tarde_sel} /> :
                            <Image
                              style={{ height: '60px' }}
                              src={icon_tarde} />}
                        </Row>
                        <Row style={{ justifyContent: 'center', marginTop: '10px' }}>Tarde</Row>
                      </Col>
                      <Col>
                        <Row style={{ justifyContent: 'center' }} onClick={(event) => { handleHora(event, 'noche'); } }>
                          {horario_elegido == "noche" ?
                            <Image
                              style={{ height: '60px' }}
                              src={icon_noche_sel} /> :
                            <Image
                              style={{ height: '60px' }}
                              src={icon_noche} />}
                        </Row><Row style={{ justifyContent: 'center', marginTop: '10px' }}>
                          Noche
                        </Row>
                      </Col>
                    </Row>
                  </> :
                  <></>}

                {paso == 5 ?
                  <>
                    <Row style={{ width: '899px' }}></Row>
                    <Row style={{ justifyContent: 'center', color: '#8d8d8d', fontSize: '15px', marginBottom: '20px' }}>Indicanos la <span style={{ color: '#626262', fontSize: '15px', fontStyle: 'italic', marginLeft: '5px', marginRight: '5px' }}> cantidad de invitados </span> <span style={{ color: '#8d8d8d', fontSize: '15px' }}> que tendras</span></Row>


                    <Form.Group controlId="formBasicAsistentes" style={{ marginLeft: '35%', marginRight: '35%' }}>
                      <Form.Control onChange={handleAsistentes} required type="number" placeholder="0" value={Asistentes} />
                    </Form.Group>

                    <div style={{ backgroundColor: 'lightgray', height: '1px', width: '100%', marginBottom: '20px', marginTop: '10px' }}></div>

                    <Row style={{ justifyContent: 'center', color: '#8d8d8d', fontSize: '15px', marginTop: '20px' }}>Selecciona el <span style={{ color: '#626262', fontSize: '15px', fontStyle: 'italic', marginLeft: '5px', marginRight: '5px' }}> área dentro del club </span> <span style={{ color: '#8d8d8d', fontSize: '15px' }}> para tu evento.</span></Row>
                    <Row style={{ marginLeft: '17%', marginRight: '17%', justifyContent: 'center', color: '#8d8d8d', fontSize: '15px', marginBottom: '20px', marginTop: '0px' }}>Te mostraremos activos los que estén disponibles en la hora y fecha que configuraste</Row>

                    <Col style={{ display: 'flex', flexFlow: 'wrap', marginTop: '60px', justifyContent: 'center' }}>
                    <div id="loaderPlaces" className="loader" style={{display:'none' ,marginTop:'30px' , color:'orange'}}></div>
                      {Agenda.map(client => {

                        return (
                          <>
                            {(client.id).toString() == LocalizacionElegida ?
                              <Col className="" style={{ minWidth: '280px', maxWidth: '280px', height: '250px', marginLeft: '5px', marginBottom: '5px', backgroundColor: '#a0a0a0' }} onClick={() => { handleLocalizacionElegida(client); } }>
                                {client.imagen == 'chimenea' ?
                                  <Image style={{ minWidth: '250px', maxWidth: '250px', height: '200px' }} src={chimenea}></Image>
                                  : <></>}
                                {client.imagen == 'reservado' ?
                                  <Image style={{ minWidth: '250px', maxWidth: '250px', height: '200px' }} src={reservado}></Image>
                                  : <></>}
                                {client.imagen == 'salon' ?
                                  <Image style={{ minWidth: '250px', maxWidth: '250px', height: '200px' }} src={salon}></Image>
                                  : <></>}
                                {client.imagen == 'barra' ?
                                  <Image style={{ minWidth: '250px', maxWidth: '250px', height: '200px' }} src={barra}></Image>
                                  : <></>}
                                {client.imagen == 'salagrande' ?
                                  <Image style={{ minWidth: '250px', maxWidth: '250px', height: '200px' }} src={salagrande}></Image>
                                  : <></>}
                                {client.imagen == 'jardin' ?
                                  <Image style={{ minWidth: '250px', maxWidth: '250px', height: '200px' }} src={jardin}></Image>
                                  : <></>}
                                <span><h6 style={{ marginBottom: '-5px' }}>{client.nombre}</h6></span>
                                <span style={{ fontSize: '14px', color: 'lightgray' }}>{client.descripcion}</span>
                              </Col> :
                              <Col className="" style={{ minWidth: '280px', maxWidth: '280px', height: '250px', marginLeft: '5px', marginBottom: '5px' }} onClick={() => { handleLocalizacionElegida(client); } }>
                                {client.imagen == 'chimenea' ?
                                  <Image style={{ minWidth: '250px', maxWidth: '250px', height: '200px' }} src={chimenea}></Image>
                                  : <></>}
                                {client.imagen == 'reservado' ?
                                  <Image style={{ minWidth: '250px', maxWidth: '250px', height: '200px' }} src={reservado}></Image>
                                  : <></>}
                                {client.imagen == 'salon' ?
                                  <Image style={{ minWidth: '250px', maxWidth: '250px', height: '200px' }} src={salon}></Image>
                                  : <></>}
                                {client.imagen == 'barra' ?
                                  <Image style={{ minWidth: '250px', maxWidth: '250px', height: '200px' }} src={barra}></Image>
                                  : <></>}
                                {client.imagen == 'salagrande' ?
                                  <Image style={{ minWidth: '250px', maxWidth: '250px', height: '200px' }} src={salagrande}></Image>
                                  : <></>}
                                {client.imagen == 'jardin' ?
                                  <Image style={{ minWidth: '250px', maxWidth: '250px', height: '200px' }} src={jardin}></Image>
                                  : <></>}
                                <span><h6 style={{ marginBottom: '-5px' }}>{client.nombre}</h6></span>
                                <span style={{ fontSize: '14px', color: 'lightgray' }}>{client.descripcion}</span>
                              </Col>}
                          </>
                        );

                      })}

                    </Col>


                  </> :
                  <></>}

                {paso == 6 ?


                  <><><><div>
                    <Row style={{ width: '899px' }}></Row>
                    <Row className="mb-3" style={{ marginTop: '40px' }}><Col><h5>RESUMEN DE SU CONTRATACIÓN</h5>
                      <Row style={{ backgroundColor: '#f3f4f4', borderColor: '#dfdfdf', borderWidth: '1px', borderStyle: 'solid', marginTop: '20px' }}></Row>
                    </Col></Row>


                    {longDesplegables.map( paso=>{
                      if (opcionesSeleccionadas[paso.id].length>0) return(
                        <Row className="justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>




                              <Col>
                              { paso.id == 0 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={uno} />:<></>}
                              { paso.id == 1 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={dos} />:<></>}
                              { paso.id == 2 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={tres} />:<></>}
                              { paso.id == 3 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={cuatro} />:<></>}
                              { paso.id == 4 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={cinco} />:<></>}
                              { paso.id == 5 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={seis} />:<></>}
                              { paso.id == 6 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={siete} />:<></>}
                              { paso.id == 7 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={ocho} />:<></>}
                              { paso.id == 8 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={nueve} />:<></>}
                              { paso.id == 9 ?
                                <Image
                                  style={{ height: '30px' }}
                                  src={diez} />:<></>}


                              <Row className="mb-3" style={{marginTop:'25px'}}><Col><h5>{paso.tipo.toUpperCase()}</h5></Col></Row>


                              {opcionesSeleccionadas[paso.id].map(client => {
                                return (
                                  <Row className="mb-3" style={{}}>
                                    <Col className="">
                                      <span><h6>{client.nombre}</h6></span>
                                    </Col>
                                  </Row>
                                );

                              })}


                        </Col>
                        </Row>
                      )
                    })}

{/*
                    <Row className="justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>
                      <Col>
                        <Image style={{ height: '30px' }} src={uno} />
                      </Col>
                    </Row>

                    <Row className="mb-3" style={{}}><Col><h5>ENTRANTES</h5></Col></Row>
                    {subscriptions.map(client => {
                      return (
                        <Row className="mb-3" style={{}}>
                          <Col className="">
                            <span><h6>{client.nombre}</h6></span>
                          </Col>
                        </Row>
                      );

                    })}

                    <Row className="justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>
                      <Col>
                        <Image style={{ height: '30px' }} src={dos} />
                      </Col>
                    </Row>

                    <Row className="mb-3" style={{}}><Col><h5>PLATO PRINCIPAL</h5></Col></Row>
                    {PlatosPrincipals.map(client => {
                      return (
                        <Row className="mb-3" style={{}}>
                          <Col className="">
                            <span><h6>{client.nombre}</h6></span>
                          </Col>
                        </Row>
                      );

                    })}

                    {Extras.length > 0 ?

                      <><Row className="justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>
                        <Col>
                          <Image style={{ height: '30px' }} src={tres} />
                        </Col>
                      </Row><Row className="mb-3" style={{}}><Col><h5>SERVICIOS EXTRA</h5></Col></Row></> : <></>}
                    {Extras.map(client => {
                      return (
                        <Row className="mb-3" style={{}}>
                          <Col className="">
                            <span><h6>{client.nombre}</h6></span>
                          </Col>
                        </Row>
                      );

                    })}<></>

                  */}

                    <Row className="justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>
                      <Col>

                      {  TotalSecs==1 ?
                        <Image style={{ height: '30px' }} src={dos} />:<></>
                    }
                    {  TotalSecs==2 ?
                        <Image style={{ height: '30px' }} src={tres} />:<></>
                    }
                    {  TotalSecs==3 ?
                        <Image style={{ height: '30px' }} src={cuatro} />:<></>
                    }
                    {  TotalSecs==4 ?
                        <Image style={{ height: '30px' }} src={cinco} />:<></>
                    }
                    {  TotalSecs==5 ?
                        <Image style={{ height: '30px' }} src={seis} />:<></>
                    }
                    {  TotalSecs==6 ?
                        <Image style={{ height: '30px' }} src={siete} />:<></>
                    }
                    {  TotalSecs==7 ?
                        <Image style={{ height: '30px' }} src={ocho} />:<></>
                    }
                    {  TotalSecs==8 ?
                        <Image style={{ height: '30px' }} src={nueve} />:<></>
                    }
                    {  TotalSecs==9 ?
                        <Image style={{ height: '30px' }} src={diez} />:<></>
                    }
                    {  TotalSecs==10 ?
                        <Image style={{ height: '30px' }} src={once} />:<></>
                    }
                      </Col>
                    </Row>
                    <Row className="mb-3" style={{}}><Col><h5>POSTRES</h5></Col></Row>
                    <Row className="mb-3" style={{}}>
                      <Col className="">
                        <span><h6>Degustación de postres de nuestra elaboración.</h6></span>
                      </Col>
                    </Row>


                    <Row className="justify-content-center" style={{ marginTop: '25px', marginBottom: '25px' }}>
                      <Col>
                      {  TotalSecs==1 ?
                              <Image style={{ height: '30px' }} src={tres} />:<></>
                          }
                          {  TotalSecs==2 ?
                              <Image style={{ height: '30px' }} src={cuatro} />:<></>
                          }
                          {  TotalSecs==3 ?
                              <Image style={{ height: '30px' }} src={cinco} />:<></>
                          }
                          {  TotalSecs==4 ?
                              <Image style={{ height: '30px' }} src={seis} />:<></>
                          }
                          {  TotalSecs==5 ?
                              <Image style={{ height: '30px' }} src={siete} />:<></>
                          }
                          {  TotalSecs==6 ?
                              <Image style={{ height: '30px' }} src={ocho} />:<></>
                          }
                          {  TotalSecs==7 ?
                              <Image style={{ height: '30px' }} src={nueve} />:<></>
                          }
                          {  TotalSecs==8 ?
                              <Image style={{ height: '30px' }} src={diez} />:<></>
                          }
                          {  TotalSecs==9 ?
                              <Image style={{ height: '30px' }} src={once} />:<></>
                          }
                          {  TotalSecs==10 ?
                              <Image style={{ height: '30px' }} src={doce} />:<></>
                          }
                      </Col>
                    </Row>
                    <Row className="mb-3" style={{}}><Col><h5>BEBIDA</h5></Col></Row>
                    <Row className="mb-3" style={{}}>
                      <Col className="">
                        <span><h6>Cerveza de grifo, vino tinto y blanco, refrescos y agua.</h6></span>
                      </Col>
                    </Row>

                    <Row style={{ backgroundColor: '#f3f4f4', borderColor: '#dfdfdf', borderWidth: '1px', borderStyle: 'solid', marginTop: '20px' }}></Row>

                  </div>


                    <div style={{ backgroundColor: 'white', height: '20px', width: '100%', marginBottom: '60px' }}></div>
                    <Row style={{ backgroundColor: '#fafafa', borderColor: '#dfdfdf', borderWidth: '1px', borderStyle: 'solid' }}>
                      <Col>

                        <Row style={{ justifyContent: 'center', marginTop: '30px', fontSize: 'medium' }}>
                          <Col style={{ textAlign: 'center', color: 'black' }}>Número de invitados
                            <span style={{ marginLeft: '5px', textAlign: 'initial', color: 'gray' }}>{Asistentes} Personas</span></Col>
                        </Row>
                        <Row style={{ justifyContent: 'init', marginTop: '-5px', marginBottom: '0px', fontSize: 'medium' }}>
                          <Col style={{ textAlign: 'center', color: 'black' }}>Localización
                            <span style={{ marginLeft: '5px', textAlign: 'initial', color: 'gray' }}>{localizacion_elegida}</span></Col>
                        </Row>
                        <Row style={{ justifyContent: 'init', marginTop: '-5px', marginBottom: '0px', fontSize: 'medium' }}>
                          <Col style={{ textAlign: 'center', color: 'black' }}>Fecha
                            <span style={{ marginLeft: '5px', textAlign: 'initial', color: 'gray' }}>{fecha_consulta}</span></Col>
                        </Row>
                        <Row style={{ justifyContent: 'init', marginTop: '-5px', marginBottom: '30px', fontSize: 'medium' }}>
                          <Col style={{ textAlign: 'center', color: 'black' }}>Horario
                            <span style={{ marginLeft: '5px', textAlign: 'initial', color: 'gray' }}>Por la {horario_elegido}</span></Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row style={{ backgroundColor: '#f3f4f4', borderColor: '#dfdfdf', borderWidth: '1px', borderStyle: 'solid' }}>
                      <Col>

                        <Row style={{ justifyContent: 'init', marginTop: '30px', fontSize: 'medium' }}>
                          <Col style={{ textAlign: 'initial' }}>Precio del menú</Col>
                          <Col style={{ textAlign: 'end' }}>EUR {totalMenu}.00</Col>
                        </Row>
                        <Row style={{ justifyContent: 'init', marginTop: '-5px', marginBottom: '30px', fontSize: 'medium' }}>
                          <Col style={{ textAlign: 'initial' }}>Precio de los servicios extra.</Col>
                          <Col style={{ textAlign: 'end' }}>EUR {totalExtras}.00</Col>
                        </Row>
                        <Row style={{ justifyContent: 'init', marginTop: '-5px', marginBottom: '30px', fontSize: 'medium' }}>
                          <Col style={{ textAlign: 'initial' }}>COSTO TOTAL</Col>
                          <Col style={{ textAlign: 'end' }}>EUR {totalExtras + (totalMenu * Asistentes)}.00</Col>
                        </Row>
                        <Row style={{ justifyContent: 'init', marginTop: '-35px', marginBottom: '30px', fontSize: 'small' }}>
                          <Col style={{ textAlign: 'initial', color: '#bf822b' }}>({Asistentes} Invitados)</Col>
                        </Row>
                      </Col>
                    </Row>

                  </>
                    <Row style={{ marginTop: '80px', justifyContent: 'center' }}>
                      <Image style={{ height: '100px' }} src={pagoAnticipo} />
                    </Row>
                    <Row style={{ marginTop: '20px', justifyContent: 'center' }}>
                      <h6>Para reservar, realiza un anticipo de EUR 300.00*</h6>
                    </Row>
                    <Row style={{ marginTop: '-5px', justifyContent: 'center' }}>
                      <p style={{ color: 'gray', fontSize: 'small' }}>*EUR 9.00 de comisiones del cobro por tarjeta</p>
                    </Row>
                  </><>
                      <Row className="mb-3" style={{ marginTop: '40px' }}><Col><h5>REALIZAR PAGO DE RESERVA</h5>
                        <Row style={{ backgroundColor: '#f3f4f4', borderColor: '#dfdfdf', borderWidth: '1px', borderStyle: 'solid', marginTop: '20px' }}></Row>
                      </Col></Row>

                      <Form.Row className="mb-3">
                        <Col>
                          <Row style={{ borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px', width: '100%', marginLeft: '0px', borderColor: '#ced4da' }}>
                            <Col style={{ maxWidth: '25px', display: 'contents' }}><Image src={iconoNombre} style={{ height: '23px', width: '20px', marginTop: '6px', marginLeft: '10px' }} /></Col>
                            <Col style={{ marginLeft: '-15px' }}><Form.Control style={{ borderStyle: 'hidden', boxShadow: 'none' }} onChange={changeCardName} required type="email" placeholder="Cardholder full name" /></Col>
                          </Row>
                        </Col>
                      </Form.Row>
                      <Form.Group>
                        <Form.Row className="info-credit-card">
                          <Col>
                            <Row style={{ borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px', width: '100%', marginLeft: '0px', borderColor: '#ced4da' }}>
                              <Col style={{ maxWidth: '25px', display: 'contents' }}><Image src={iconoTarjeta} style={{ height: '17px', width: '20px', marginTop: '10px', marginLeft: '10px' }} /></Col>
                              <Col style={{ marginLeft: '-15px' }}><Form.Control style={{ borderStyle: 'hidden', boxShadow: 'none' }} maxLength={19} onChange={changeCardNumber} required type="email" placeholder="Card Number" /></Col>
                            </Row>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                      <Form.Group>
                        <Form.Row className="info-credit-card">

                          <Col md="2">
                            <Form.Control style={{ boxShadow: 'none' }} required type="text" minLength={2} maxLength={2} placeholder="MM" onChange={changeMonth} />
                          </Col>
                          <Col md="2">
                            <Form.Control
                              required
                              ref={yearInputRef}
                              style={{ boxShadow: 'none' }}
                              type="text"
                              minLength={4}
                              maxLength={4}
                              placeholder="YYYY"
                              onChange={changeYear} />
                          </Col>
                          <Col md="2">
                            <Form.Control
                              required
                              style={{ boxShadow: 'none' }}
                              ref={cvvInputRef}
                              type="text"
                              minLength={3}
                              maxLength={4}
                              placeholder="CVV"
                              onChange={changeCVV} />
                          </Col>
                        </Form.Row>
                      </Form.Group>
                      {alerta && (
                        <Alert variant='success'>
                          Por favor, permite abrir la ventana flotante para poder confirmar el pago con tu entidad financiera
                        </Alert>)}
                      {showError && (
                        <Alert variant='danger'>
                          {errorMessage}
                        </Alert>)}

                    </></> :
                  <></>}
                {paso == 7 ?
                  <>
                    <Row style={{ width: '899px' }}></Row>
                    <Image style={{ minWidth: '250px', maxWidth: '250px' }} src={exitopago}></Image>
                    <Row style={{ backgroundColor: '#f3f4f4', borderColor: '#dfdfdf', borderWidth: '1px', borderStyle: 'solid', marginTop: '20px' }}></Row>
                    <Row className="mb-3" style={{ marginTop: '40px' }}><Col><h5>¡PREPARATE PARA TU EVENTO!</h5></Col></Row>
                    <Row className="mb-3" style={{ marginTop: '0px' }}><Col style={{ marginTop: '-25px', fontSize: '17px' }}>Tu pago fue procesado con éxito.</Col></Row>
                  </> : <></>}


                <Row className="justify-content-center">
                  <div className="rounded px-5 pb-4" style={{ marginTop: '60px', width: '100%' }}>
                    <Row style={{ justifyContent: 'center' }}>



                      {paso == 3 ?
                        <>

                          <Col>
                            <Row style={{}}>
                              <Button style={{ marginRight: '5px', backgroundColor: 'gray', borderColor: 'gray', height: '38px' }} className="my-2 btn-block" onClick={(e) => { handleRetrocedePaso(); } }>
                                Regresar a configurar
                                {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                              </Button>
                            </Row>
                          </Col>
                          <Col>
                            <Row style={{}}>

                              <Button style={{ marginLeft: '5px' }} className="my-2 login btn-block" onClick={() => { avanzarMenu(); } }>
                                Continuar
                                {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                              </Button>
                            </Row>
                          </Col></>
                        :
                        <>
                        </>}

                      {paso > 0 && paso != 3 && paso!=1 && paso < 7 ?

                        <><Col>
                          <Row style={{ justifyContent: 'end' }}>
                            <Button style={{ marginRight: '5px', backgroundColor: 'gray', width: '150px', borderColor: 'gray', height: '38px' }} className="my-2 btn-block" onClick={(e) => { handleRetrocedePaso(); } }>
                              Regresar
                              {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                            </Button>
                          </Row>
                        </Col><Col>
                            <Row style={{ width: '150px' }}>
                              {canContinue ?
                                <Button style={{ marginLeft: '5px' }} className="my-2 login btn-block" onClick={() => { avanzarMenu(); } }>
                                  Continuar
                                  {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                                </Button>
                                :
                                <Button type="submit" className="my-2 loginDeactive btn-block" style={{ cursor: 'not-allowed', marginLeft: '5px' }}>
                                  Continuar
                                  {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                                </Button>}
                            </Row>
                          </Col></>

                        :
                        <></>}

                      {paso == 0 || paso == 7 ?
                        <><div className="rounded px-5 pb-4" style={{ width: '890px' }}> </div><>
                          <Row style={{ width: '150px' }}>
                            {canContinue ?
                              <Button className="my-2 login btn-block" onClick={() => { handleAvanzaPaso(); } }>
                                Continuar
                                {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                              </Button>
                              :
                              <Button type="submit" className="my-2 loginDeactive btn-block" style={{ cursor: 'not-allowed' }}>
                                Continuar
                                {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                              </Button>}
                          </Row>
                        </></>
                        :
                        <></>}

                    {paso == 1 ?
                        <><div className="rounded px-5 pb-4" style={{ width: '890px' }}> </div><>
                          <Row style={{ width: '150px' }}>
                            {canContinue || true?
                              <Button className="my-2 login btn-block" onClick={() => { handleRetrocedePaso(); } }>
                                Retroceder
                                {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                              </Button>
                              :
                              <Button type="submit" className="my-2 loginDeactive btn-block" style={{ cursor: 'not-allowed' }}>
                                Retroceder
                                {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                              </Button>}
                          </Row>
                        </></>
                        :
                        <></>}      

                    </Row>
                  </div>
                </Row>
              </div>
            </Row>






            <Row className="mt-auto">
              <Col className="px-0">
                {/*<Footer/>*/}
              </Col>
            </Row>
          </Container></>
  );
}


export default MainPageEventosLaGloria;

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function isNumber(myString) {
  return /^\d+$/.test(myString);
}

function byteToHex(byte) {
  return ('0' + byte.toString(16)).slice(-2);
}

function generateId(len = 40) {
  var arr = new Uint8Array(len / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, byteToHex).join("");
}
