var private_key=
"-----BEGIN RSA PRIVATE KEY-----\n"+
"MIIEowIBAAKCAQEAo4HKj+4lzN7OBNe3CHTDs/dqbrmOtG/RFQnfiFiwVdbNeSv0\n"+
"Qx4JMdyMUlZ52WxZzDWQ5/aLm83ib2R1NJzYJOB5tPGFS9lSK+SQ3+LhW3jkKTBF\n"+
"fi+5VyRaB2Ft2go5TVvpWnX+csna4ZfCXzQYUebCSYhiQnTQcDQ2i178aXgvnilX\n"+
"Kha3Biq4qCwP7F57FOt3toeYXpQw/lrBc1ZutwVgmmGftO5ZLfzTqSfvHMPw21Db\n"+
"lNSnWjzAb/f76cDQNRs8kCIqXTZmOHwbfstISpgqSi/oD9Ot3PN7Mr2IGnfCkc6v\n"+
"tqJNKjoyuTB6J94OcUunxgjw8aGfSa2GgdyDsQIDAQABAoIBAF9iYCEG9NZHRDhe\n"+
"/0/XjjN2qohdh3qLoJV9ZQHNe1EYNlXx5lUhOIv19YYzHHJSWzBeL2Ki2V7TBL2n\n"+
"BRjkRtN8gIOmQfS1mtIwTLPPT1hldfTxTBkJDj5f3oVfuQ3x2OvgKomzO/UK/L2O\n"+
"Ir4XTfi5fa4X+fp62v4Z95fmmamMnFOq1ITH4TciG/XVk4EkuxC7efzplDGWoIxo\n"+
"igcnHQ34cznS93HMvwU1+98cOJaz45EY+cYCnWULbYgv/yP8T9NVxltj5w/gB7ub\n"+
"NRKbCZ+5U/FIfBwuwqkSj8xJ27mFQwrkKSCn082zyhGO6s6ZawLNFOuduR133V3V\n"+
"G9VUCGECgYEA2XwFUH0w+bEjS0Itc8VbDpFLKAlLIA0YomPq9VyPm3ted2QUuXdp\n"+
"KQelA8WlJ6AydPbhxSjikEQFsNAH8NB+b1D3/z+5JfZoJiKLbt7SdCJ6mvHvFA5m\n"+
"nJfxe5BRfwl0qAAVyMHlKcpk6QBl7zk9OywO7rbcA7I+BqBZ+hsntocCgYEAwHaf\n"+
"ZCdS3tGNN/3m+keHAvMA0m7OU9FGuZkJYuJThO/DZqcE16NTCxFdUoGWp6XWyQzL\n"+
"JraJPEPZaE8DcHnD4TAqkSPF8NL3BJnpRwWJHJ2EHhXMtfn1/oWhCQQ5vDpOY7An\n"+
"cGDitnzenDvdXoqJFYFkofeI0TXY8bTTq9RAygcCgYEAn7I0yMyUuNR8oRd//qGt\n"+
"Yj4IHObuFiPnsd6CuhZ0kOv/CtYfbaykOG+XmPR5eGZmhoJ+d+K0stBwCUxIeGjw\n"+
"XlbrM6+cCactBMua7ReLG6Hxu3LpZiXI7TWZKW1omkrcMTyWwmq2FoC6pmkXcJjs\n"+
"QFXyxRDa74/jgcQfLcrg12MCgYBmcDzSD+QZ2KszfB6uY9fGiBSN7JBBKIheTMgk\n"+
"K5aMkRkN0CPRqoqOv4VOXs8TGD/D7t1KE6nju7FBxZxtB0Js3zgo3EOEtKTpfAof\n"+
"1SBvbJ9fa/bzfMGj4PgfVnJsbxQ+FwpG1CqXOss5mNtwcIgaPC6VzqyxvdlkfPyU\n"+
"3npFjQKBgBOIl548t5mlZf3NMP8adDa6su8F1rilPyzXUD9kqe7dq8DXsCbcrnus\n"+
"4VKGFhgkAjrhRPu9vdWJNBiGt2omiUImodQ1u6juAHHAnIiz7vwDCItR9lDsBOdv\n"+
"PSaDC9DJPLcYoBoD9gmLX5OvpraqK0ylHLs3tuiV3zW6Ns6Jxm3G\n"+
"-----END RSA PRIVATE KEY-----"

export default private_key;