import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Image, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../../assets/img/hosteleria.png';
import Logo2 from '../../assets/img/facebook.png';
import Logo3 from '../../assets/img/instagram.png';


const FooterMenu:FunctionComponent<{}> = () => {
  return (
    <div className='pie-de-pagina'>
    <Nav id="footer-dashboard" /*className="justify-content-md-center "*/ style={{
      marginTop:'68px', 
      marginBottom:'68px', 
      height:'298px',
      backgroundColor:'#af9453'
      }}>

        <Row className='pie-de-pagina-dentro' style={{fontFamily:'Brandon Grothesque Light', fontSize:'16px', letterSpacing:'0.05em'}}>
          <div className='pie-de-pagina-1columna' >
          <Row>Av. Guadalix SN</Row>
          <Row>28120, Algete</Row>
          <Row>España</Row>
          </div>
          <div className='pie-de-pagina-2columna'>
          <Row>Tel.: 914-123-456</Row>
          <Row>Fax: 914-123-456</Row>
          <Row>info@villacaprice.es</Row>
          </div>
          <div className='pie-de-pagina-3columna'>
          <Row>© 2023 Villa Caprice</Row>
          <Row style={{color:'transparent'}}>.</Row>
          <Row>Creado por 101OBeX</Row>
          </div>
          <div className='rallas'>

          <div className='rallas2'></div>

          </div>

          <div className='rallas_'>
            <div className='rallas2'></div>
          </div>

          <img  className='hosteleria' src={Logo} />
          <div className='facebook'>
          <img   src={Logo2} />
          <img  style={{marginLeft:'10px'}} src={Logo3} />

          <div className='boton_reserva' onClick={()=>{
            console.log("PULSADO")
            window.open("https://raulnogales.wixsite.com/villacaprice/reservas","_self")
            }}>RESERVA UNA REUNIÓN</div>

          </div>
        </Row>

    </Nav>
    </div>
  );
}


export default FooterMenu;